<template>
  <div :class="{ 'personal-page': true, mobile: isMobile }">
    <div class="top">
      <v-img class="img" v-if="ban" :src="ban" cover alt="" />
    </div>
    <div class="d-flex flex-column flex-lg-row">
      <div class="d-flex mb-n10 flex-column align-center">
        <div class="avatar-box align-self-lg-start rounded-circle">
          <v-avatar :size="180" style="overflow: visible; position: relative">
            <v-img :src="src" alt="" />
            <img
              v-if="myInfo.grade == 3"
              src="@/assets/icons/icon_v.png"
              alt="V"
              class="bigv"
            />
          </v-avatar>
        </div>
      </div>
      <div
        class="ml-lg-8 mt-5 mt-lg-6 d-flex flex-column align-center align-lg-start"
      >
        <div style="font-size: 40px; font-weight: bold">
          {{ myInfo.userName  ? decodeURIComponent(myInfo.userName ): ''}}
        </div>
        <div class="no_copy">
          <span v-if="!isMobile" class="No">{{ myInfo.did }}</span>
          <span v-if="isMobile" class="No">{{ myInfo.did | didfilter }}</span>
          <img
            class="copy ml-2"
            :class="{ click: isCopy }"
            src="@/assets/icons/icon_copy.png"
            @click="onCopyClick"
          />
          <span
            style="
              margin-left: 47px;
              width: 18px;
              height: 19px;
              font-size: 13px;
            "
            >{{ $t("profile_link") }}</span
          >
          <img
            class="copyProfile ml-2"
            :class="{ copy_profile: isCopyProfile }"
            src="@/assets/icons/icon_copy.png"
            @click="onProfileClick"
          />
          <img
            class="mr-2 mb-n1"
            style="margin-left: 48px; width: 18px; height: 19px"
            src="@/assets/icons/icon_rili.png"
            alt=""
          />
          <span class="mt-2" style="font-size: 13px">{{ joinTime }}</span>
        </div>
        <div class="linkurl" style="margin-top: -3px">
          <div
            class="mr-6 mt-1 media"
            v-if="myInfo.instagram && myInfo.instagram != 'null'"
          >
             <a :href="Instagram" target="_blank" @click="mediaClick('instagram')">
                 <img
              class="mr-2 mb-n2"
              style="margin-left: 0"
              src="@/assets/icons/icon_Instagram.png"
              alt=""
            />
            <span class="text-caption">{{ decodeURIComponent(myInfo.instagram)  }}</span>
            </a>
          </div>
              
           
          <div
            class="mr-6 mt-1 media"
            v-if="myInfo.twitter && myInfo.twitter != 'null' "
          >
            <a :href="Twitter" target="_blank" @click="mediaClick('twitter')">
              <img
                class="mr-2 mb-n2"
                src="@/assets/icons/icon_twitter.png"
                alt=""
              />
              <span class="text-caption">{{ decodeURIComponent(myInfo.twitterName)}}</span>
                <img
               v-if="myInfo.twitterVerify"
              class="mr-2 mb-n1"
              style="margin-left: 4px;width:16px;height:16px;"
              src="@/assets/icons/icon_verified.png"
              alt=""
            />
            </a>
          </div>
          <div
            class="mr-6 mt-1 media"
            v-if="myInfo.youtube && myInfo.youtube != 'null'"
          >
            <a :href="Youtube" target="_blank" @click="mediaClick('youtube')">
              <img
                class="mr-2 mb-n2"
                src="@/assets/icons/icon_youtube.png"
                alt=""
              />
              <span class="text-caption">{{ decodeURIComponent(myInfo.youtube) }}</span>
            </a>
          </div>
          <div
            class="mr-6 mt-1 media"
            v-if="myInfo.homepage && myInfo.homepage != 'null'"
          >
            <a :href="Homepage" target="_blank"  @click="mediaClick('homepage')">
              <img
                class="mr-2 mb-n2"
                src="@/assets/icons/icon_site.png"
                alt=""
              />
              <span class="text-caption">{{ decodeURIComponent(myInfo.homepage) }}</span>
            </a>
          </div>
        </div>
        <div
          class="introduction d-none d-lg-block"
          v-html="myInfo.synopsis"
        ></div>
        <!-- //展示的三张图片 -->
        <div class="imgbox d-flex flex-row" v-if="!isMobile">
          <div class="box" v-for="(item, index) in imgList" :key="index">
            <img
              :src="item.src"
              alt=""
              @click="openLargeImg(item)"
              class="ml-1 mb-5"
            />
          </div>
        </div>
        <div
          class="info-box d-flex flex-column flex-lg-row align-start align-lg-center"
          style="margin-top: 10px"
        >
          <UserCount :myInfo="myInfo" />
          <ScrollX>
            <div class="ingbox d-flex flex-row" v-if="isMobile">
              <div class="box ml-1 mb-5" v-for="item in imgList" :key="item.id">
                <img :src="item.src" alt="" @click="openLargeImg(item)" />
              </div>
            </div>
          </ScrollX>
          <div
            class="d-flex flex-row ml-lg-14 mt-11 mt-lg-0 align-center"
            v-if="myInfo.fansNumber > 0"
          >
            <div style="font-weight: bold">
              {{ $t("avatarAvatarInfoFans") }}
            </div>
            <div
              class="mt-lg-2"
              style="
                position: relative;
                height: 30px;
                margin-left: 15px;
                margin-bottom: 10px;
              "
            >
              <div
                class="avatar"
                @click="avatarClick"
                :style="{ left: `${index * 18}px` }"
                v-for="(info, index) of avatars"
                :key="index"
              >
                <v-avatar size="28">
                  <!-- <v-img :src="info.src" alt="" />  -->
                  <v-img
                    v-if="info.src == null"
                    src="@/assets/default.png"
                    alt=""
                  />
                  <v-img v-else :src="info.src" alt="" />
                </v-avatar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div class="" style="min-height: 500px">
      <Collections @list="getlist" />
    </div>

    <div class="right_bottom" v-if="!isMobile && showRightBottom">
      <img src="@/assets/icons/btn_play1.png" @click="openCarousel" alt="" />
    </div>

    <v-dialog v-model="opencard">
      <carousel
        class="otherpage"
        :list="list"
        v-if="opencard"
        @getOpen="OpenCard"
      ></carousel>
    </v-dialog>
    <v-dialog fullscreen v-model="openLargemg">
      <large-img
        ref="largeDialog"
        v-if="openLargemg"
        :productPic="productPic"
        @getOpen="Open"
      >
      </large-img>
    </v-dialog>
  </div>
</template>

<script lang='js'>
	import UserCount from "@/components/UserCount.vue";
	import Collections from "./components/Collections.vue";
	import api from "@/api";
	import {
		getFileSrc
	} from "@/utils/file";
	import defaultImg from "@/assets/default.png";
	import Carousel from "@/components/carousel";
	import LargeImg from "@/components/largeImg.vue";
  import ScrollX from "@/components/scroll/ScrollX.vue";
  import { getSettingArr } from "@/utils/helper";

	const BANNER_KEY = "BANNER";
	const AVATAR_KEY = "AVATAR";
	const SYNOPSIS_KEY = "SYNOPSIS";

	export default {
		name: "PersonalPage",
		components: {
			UserCount,
			Collections,
			Carousel,
			LargeImg,
			ScrollX
		},
		props: {},
		data: function() {
			return {
				myInfo: {},
				avatars: [],
				src: "",
				ban: "",
				defaultImg,
				opencard: false,
				list: [],

				isCopy: false,
				isCopyProfile:false,
                showRightBottom: false,

				loading: false,
				selection: 1,
				Twitter:'',
				Youtube:'',
        Homepage:'',
        Instagram:'',

				//展示图片
				imgList:[
					//接口获取的三张图
				],
				openLargemg:false,
				productPic:'',
				joinTime:'',
				// isCollection:true,
				// isNft:false,
				// isFile:true,
				// cardType:'',
				// tagtype:''
			};
		},
		computed: {
			isMobile: function() {
				return this.$vuetify.breakpoint.mobile;
			}
		},
		filters: {
			didfilter: function(value) {
				if (value && value.length > 24) {
					return value.substr(0, 12) + "..." + value.substr(-12);
				} else {
					return "";
				}
			}
		},
		mounted() {
      this.showNoRead();
			this.getInfo();
      window.eventBus.$on('LangChange', this.onLangChange);
      
      
		},
		methods: {
      mediaClick(name){
       this.$gtag.event('个人主页-'+name, { 'event_category': 'Click', 'event_label': name })
    },
			onLangChange() {
				this.avatars=[];
				this.imgList=[]
				
			  this.getInfo();
			},
		// 	nftbtn(){
		// 		this.isNft = false;
    //    this.isCollection = true;
	  //  this.isFile = true;
	  //  this.cardType = "nftType"
	  //  this.tagtype = "mycollection"
		// 	},
		// 	collectionbtn(){
 		// 		this.isNft = true;
    //    this.isCollection = false;
	  //  this.isFile = true;
	  //  this.cardType = "collectionType"
	  //  this.tagtype = "mycollection"
		// 	},
		// 	filebtn(){
		// 	 this.isNft = true;
    //    this.isCollection = true;
	  //  this.isFile = false;
	  //  this.cardType = "fileType"
	  //  this.tagtype = "mycollection"
		// 	},
		// 	nftbtn1(){
		// 				this.isNft = false;
    //    this.isCollection = true;
	 
	  //  this.cardType = "nftType"
	  //  this.tagtype = "collection"
		// 	},
		// 	collectionbtn1(){
		// 					this.isNft = true;
    //    this.isCollection = false;
	  
	  //  this.cardType = "collectionType"
	  //  this.tagtype = "collection"
		// 	},
			
			reserve() {
				this.loading = true;
				setTimeout(() => (this.loading = false), 2000);
			},
			forceUpdate() {
				this.$forceUpdate();
			},
			async getInfo() {
				// let params = { did: this.$store.state.did };
				// let res = await api.home.getMyInfo(params);

				let params = {
					did: this.$store.state.did,
					chainType: this.$store.state.chainType,
					userAddress: this.$store.state.did
				};

				let res = await api.home.createInfo(params);
				this.myInfo = res.data;

				// 获取个人描述信息
      if(res.data.synopsisImage)
      {
        console.log("vvvvvvvvvvvvvvvvvvvvvvvv")
        let imgLists = (res.data.synopsisImage).split(",");
        console.log(imgLists);
        imgLists.forEach(async (v) => {
          if (v != '') {
            let imgsrc = await getFileSrc(SYNOPSIS_KEY, v);
            this.imgList.push({ src: imgsrc });
          }
        });
        console.log("wwwwwww")
        console.log(this.imgList)
      }
	  if(this.myInfo.joinTime!=null){
	  		  let t = this.$t("otherpage_join")
	  		   let reg=new RegExp('【N】','g')//g代表全部
	  		 this.joinTime=t.replace(reg,this.myInfo.joinTime);
	  	
        }
        
          //Instagram是否含有http / https
      if(this.myInfo.instagram != null){
 let instagram=this.myInfo.instagram.toLowerCase();
 let newinstatgram=instagram.indexOf("instagram.com");
 let newinstatgram1=instagram.indexOf("https://instagram.com");
 let newinstatgram2=instagram.indexOf("http://instagram.com");

 if(newinstatgram>=0 || newinstatgram1>=0 || newinstatgram2>=0 ){
    this.Instagram = `${this.myInfo.instagram}`
   
 }else{
   this.Instagram = `https://instagram.com/${this.myInfo.instagram}`
 }
       // if(newinstatgram == 0)
       // {
       //   this.myInfo.instagram = this.myInfo.instagram.slice(14)
       // }
       // if(newinstatgram1 == 0)
       // {
       //   this.myInfo.instagram = this.myInfo.instagram.slice(22)
       // }
       // if(newinstatgram2 == 0)
       // {
       //   this.myInfo.instagram = this.myInfo.instagram.slice(21)
       // }
       
       
      }
				 	//twitter是否含有http / https
				if(this.myInfo.twitter != null){
					let newtwitter=this.myInfo.twitter.indexOf("http");
					if(newtwitter==0){
						this.Twitter = `${this.myInfo.twitter}`
					}
					if(newtwitter==-1){
						this.Twitter = `https://${this.myInfo.twitter}`
					}
					
				}
				if(this.myInfo.twitter != null){
					  let showTwitter=this.myInfo.twitter.indexOf("twitter.com/");
        			if(showTwitter == 0 )
        			{
          				this.myInfo.twitter = this.myInfo.twitter.slice(12)
        			}
        			
				}
				
					//youtube是否含有http / https
				if(this.myInfo.youtube != null){
					let newyoutube=this.myInfo.youtube.indexOf("http");
					if(newyoutube==0){
						this.Youtube = `${this.myInfo.youtube}`
					}
					if(newyoutube==-1){
						this.Youtube = `https://${this.myInfo.youtube}`
					}
					
				}
				if(this.myInfo.youtube != null){
			let showYoutube=this.myInfo.youtube.indexOf("https://www.youtube.com/channel/");
        if(showYoutube == 0 )
        {
          this.myInfo.youtube = this.myInfo.youtube.slice(32)
        }
      
				}
		
					//homepage是f否含有http / https
				if(this.myInfo.homepage != null){
					let newhomepage=this.myInfo.homepage.indexOf("http");
					if(newhomepage==0){
						this.Homepage = `${this.myInfo.homepage}`
					}
					if(newhomepage==-1){
						this.Homepage = `https://${this.myInfo.homepage}`
					}
				}
				if(this.myInfo.synopsis)
				this.myInfo.synopsis = this.myInfo.synopsis.replace(/\n/g,"<br/>")
				if(!this.myInfo) {
					this.myInfo = {
						did: this.$store.state.did,
						fansNumber: 0,
						followCount: 0,
						workCount: 0
					}
				}

				// this.avatars = res.data.fans;
				// this.avatars = this.avatars.filter(n => n);
				console.log("wxl ----------   getMyInfo")
				console.log(params)
				console.log(res)

				//头像
				if (this.myInfo.profilePhoto) {
					this.src = await getFileSrc(AVATAR_KEY, this.myInfo.profilePhoto);
				} else {
					this.src = defaultImg;
				}

				//背景
				if (this.myInfo.homeBackground&&this.myInfo.homeBackground.length>4) {
					this.ban = await getFileSrc(BANNER_KEY, this.myInfo.homeBackground);
					if (this.ban == "null") this.ban = "";
				}else{
						this.ban = defaultImg
				}
				//fans小头像
				let fanSrc = res.data.fans;
				console.log("fans数目:" + fanSrc.length);
				console.log(res.data);
				if (fanSrc.length > 0) {
					
					fanSrc.forEach(async (v) => {
						if (v != null && v.length == 46) {
							let fansrc = await getFileSrc(AVATAR_KEY, v);
							this.avatars.push({
								src: fansrc
							});

						}else{
							let fansrc = defaultImg;
							this.avatars.push({
								src: fansrc
							});
						}

					});
				}
			},
			openCarousel() {
				this.opencard = true;
			},
			OpenCard(e) {
				this.opencard = e;
			},
			//查看大图
			openLargeImg(item) {
				this.productPic = item.src
				this.openLargemg = true;
				// this.$refs.largeDialog.playVideo();
			},
			//弹框关闭后获取open值
			Open(e) {
				this.openLargemg = e;
      },
         async showNoRead(){
    const json = localStorage.getItem("key_user");
    if (json) {
           let res2 = await api.home.isNewMessage({userAddress:this.$store.state.did,pushTypes:getSettingArr()});
      let newMsgCount = res2.data;


     // 有新消息进行弹窗
     if(newMsgCount >0){
        let params={
        pageSize: 10,
        pageNumber: 1,
        owner:this.$store.state.did,
        isRead:false,
        pushTypes:getSettingArr()
      };
      let res = await api.home.sysMessage(params)
      console.log("wxl --- sysMessage",res)
      if(res.data){
         let list = res.data.list
         for (let index = 0; index < list.length; index++) {
           setTimeout(() => {
              this.$mtip({
                title:list[index].pushContract,
              });
           }, 1000);
           
         }
      }      
    }
   }
   window.eventBus.$emit("ViewMessage", true);
   },
			getlist(dt) {
				this.list = dt;
				if(this.list.length>0){
					this.showRightBottom =true
				}else{
					this.showRightBottom =false
				}
				// console.log(223,dt);
			},
			onCopyClick() {
			 this.$gtag.event('个人主页-钱包地址', { 'event_category': 'Click', 'event_label': '钱包地址' })
				var input = document.createElement('input')
				input.value = this.myInfo.did;
				document.body.appendChild(input);
				input.select();
				document.execCommand('copy');
				document.body.removeChild(input);
				this.isCopy = true;
				setTimeout(() => this.isCopy = false, 100);
			},
			onProfileClick(){
				console.log(" wxl -- myInfo ----")
				console.log( window.location.href)
				var input = document.createElement('input')
				input.value = "https://irisnet.upticknft.com/otherpage?did="+this.myInfo.did;;
				document.body.appendChild(input);
				input.select();
				document.execCommand('copy');
				document.body.removeChild(input);
				this.isCopyProfile = true;
				setTimeout(() => this.isCopyProfile = false, 100);
			},
			onMediaClick(val) {
				window.open(val, '_blank');
			},
			onMediaClick1() {
				window.open('www.baidu.com', '_blank');
			},
			avatarClick() {
				this.$router.push({name:"MyReferences",params:{
          toMyFans:true
       }})
			},
      onTabsClick(v) {
        console.log("=======================",v)
          v == 0? this.showRightBottom=true: this.showRightBottom=false;
          if( v == 0)
          {
            sessionStorage.clear("PersonalPage_Tabs")
             sessionStorage.setItem("PersonalPage_Tabs", 0);
             
            
          
          }else{
            sessionStorage.clear("PersonalPage_Tabs")
            sessionStorage.setItem("PersonalPage_Tabs", 1);
             
         
          }
          
      }
		}
	};
</script>

<style lang='scss' scoped>
a {
  text-decoration: none;
  vertical-align: middle;
  img,
  span {
    display: inline-block;
  }
}
img {
  display: inline-block;
  width: 22px;
  height: 22px;
  margin: 0 7px 0 0;
}

.personal-page {
  .top {
    height: 301px;
    .img {
      width: 100%;
      height: 100%;
       border-radius: 15px;
    }
  }

  .no_copy {
    .No {
      font-family: "\5FAE\8F6F\96C5\9ED1";
      font-size: 11px;
      color: #270645;
      padding-top: 4px;
    }

    .copy {
      width: 13px;
      height: 15px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 23px;
      cursor: pointer;
    }
    .copyProfile {
      width: 13px;
      height: 15px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 23px;
      cursor: pointer;
    }
    .click {
      margin-top: 5px;
    }
    .copy_profile {
      margin-top: 5px;
    }
  }

  .linkurl {
    margin-bottom: 12px;
    .media {
      display: inline-block;
      word-wrap: break-word;
      word-break: break-all;
      a {
        img,
        span {
          display: inline;
        }
      }
      img,
      span {
        display: inline;
      }
    }
  }

  .avatar-box {
    position: relative;
    top: -40px;
    left: 0;
    border: 6px white solid;
  }

  .introduction {
    display: -webkit-box;
    overflow: hidden;
    font-size: 13px;
    white-space: normal !important;
    text-overflow: ellipsis;
    word-wrap: break-word;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .imgbox {
    max-width: 996px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    .box {
      height: 300px;
      // width:auto;
      margin-right: 5px;

      img {
        width: auto;
        height: 300px;
        display: block;
        // object-fit: contain;
      }
    }
  }
  .info-box > div:first-of-type {
    width: 260px;
  }

  &.mobile {
    .info-box {
      width: 100%;

      > div {
        width: 100%;
      }
      .ingbox {
        margin-top: 25px;
        margin-bottom: 30px;
        // width:100%;
        .box {
          height: 350px;
          // width:263px;
          img {
            width: auto;
            height: 100%;
            // object-fit: contain;
          }
        }
      }
    }

    .linkurl {
      margin-bottom: 12px;
      .media {
        display: inline-block;
        word-wrap: break-word;
        word-break: break-all;
        // width:100%;
        a {
          img,
          span {
            display: inline;
          }
        }
        img,
        span {
          display: inline;
        }
      }
    }
  }
}

.right_bottom {
  position: fixed;
  bottom: 100px;
  right: 50px;
  z-index: 200;

  img {
    display: block;
    width: 59px;
    height: 59px;
    margin-top: 35px;
  }
}

.avatar {
  position: absolute;
  left: 0;
  top: 0;
  background: white;
  padding: 2px;
  border-radius: 50%;
  cursor: pointer;
}
.bigv {
  width: 40px;
  height: 40px;
  position: absolute;
  right: -4px;
  bottom: 3px;
}
</style>

<template>
  <v-card class="rd">
    <Work
      :hash="hash"
      :denomType="denomType"
      :fileUrl="fileUrl"
      :mycreate="mycreate"
      :createFrom="createFrom"
      :value="value"
      @imgClick="workClick"
    />
     <uComponents ref="ucom"></uComponents>
  </v-card>
  
</template>


<script>
import Work from "@/components/work/collectionCard.vue";
	import api from "@/api";

export default {
  name: "Card",
  components: { Work },
  props: {
    value: {
      type: Object,
    },
    type: {
      type: String, // auction / sell / my / collect
    },
    owerfrom: {
      type: String,
    },
    favorite: {
      type: String,
    },
    isMinPrice: {
      type: String,
    },
    mycreate: {
      type: String,
    },
    createFrom: {
      type: String,
    },
    nftList:{
      type:Array
    }
  },
  data: () => ({
    loading: false,
    selection: 1,
  }),
  computed: {
    hash: function () {
      // if(this.value.collectionType == 1){
      //    return this.value && this.value.imgUrl;
      // }else{
      //    return this.value && this.value.collectionImgUrl;
      // }
       return this.value && this.value.imgUrl;
     
    },
    denomType: function () {
      return this.value && this.value.denomType;
    },
    fileUrl: function () {
      return this.value && this.value.fileUrl;
    },
    contractCollectionId:function(){
      return this.value.id
    }
  },
  methods: {
    reserve() {
      this.loading = true;
      setTimeout(() => (this.loading = false), 2000);
    },
   async  workClick() {
     debugger
      console.log("onclicked", this.type ,this.value,this.contractCollectionId);
      let url;
      if(this.value.linkUrl)
      { 
       url = this.value.linkUrl
      }
     
      console.log("4444444",url)
      if (this.type == "createorPage") {
        this.$router.push({
             path: `/collection_create/${url}`,   
        });
      }else if(this.type == "cardPage"){
        this.$router.push({
          path: `/collection_card/${url}`,
        });
      }else if(this.type =="createorFolder")
      {
        let nftAddress = "";
        for (let index = 0; index < this.nftList.length; index++) {
         nftAddress += this.nftList[index].contractAddress + ","
          
        }
        nftAddress = nftAddress.substring(0, nftAddress.lastIndexOf(','))	
        console.log("yyyyyyyyyyyyyyy",this.nftList)
        let params = {
          contractAddress:nftAddress,
          contractCollectionId:this.contractCollectionId
        }
        let res = await api.home.addFolder(params)
        if(res.success == true)
        {
          //   this.$toast("error", this.$t("amCreateFalseTitle2"));
		   this.$toast("success", this.$t("add_success"));
        }else{
          this.$toast("error", res.msg);
          return
        }

       setTimeout(()=>{
          window.eventBus.$emit("closeChooseBtn");
       },2000)
       
      }else if(this.type == "floderPage")
      {
         this.$router.push({
             path: `/folder_create/${url}`,   
        });
      
      
      }else if(this.type =="folderIndexPage")
      {
        if(this.value.collectionType == 2)
        {
            this.$router.push({
             path: `/folder/${url}`,   
        });
        }else{
           this.$router.push({
             path: `/collection/${url}`,   
        });
        }
      
      }
      else{
          if(this.value.collectionType == 2)
        {
            this.$router.push({
             path: `/folder/${url}`,   
        });
        }else{
           this.$router.push({
             path: `/collection/${url}`,   
        });
        }
        // this.$router.push({
        //      path: `/collection/${url}`,   
        // });
      }
    },
    forceUpdate() {
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card.rd {
  overflow: hidden;
  width: 281px;
  background-image: linear-gradient(#ffffff, #ffffff),
    linear-gradient(#6f58d9, #6f58d9);
  background-blend-mode: normal, normal;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.09);
  @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
    width: 281px;
    background-image: linear-gradient(#ffffff, #ffffff),
      linear-gradient(#ffffff, #ffffff);
    background-blend-mode: normal, normal;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
  }
}
</style>

<template>
  <div class="contain" :class="{ mobile: isMobile }">
    
    <!-- 历史展示 -->
    <div class="history">
      <div class="Properties mt-4">
        <div
          class="list d-flex flex-row flex-wrap"
          :class="{ listBackground: $vuetify.theme.dark }"
        >
          <div
            class="titlelist d-flex flex-row align-center pl-5"
            :class="{ buttomBackground: $vuetify.theme.dark }"
            v-if="!isMobile"
          >
            <div class="s1" >
              {{ $t("saledetailHistoryEvent") }}
            </div>
            <div class="s2" >
              {{ $t("Quantity") }}
            </div>
            <div
              class="s3"
             
            >
              {{ $t("saledetailHistoryForm") }}
            </div>
            <div class="s4" >
              {{ $t("saledetailHistoryTo") }}
            </div>
            <div class="s5" >
              {{ $t("detailIndexTime") }}
            </div>
           
         
          </div>
          <div class="historyList" v-if="crossChianList&& crossChianList.length>0">
            <div
              class="item d-flex flex-row align-center"
              v-for="(item, index) in crossChianList"
              :key="index"
              :class="{ darkSoildBorder: $vuetify.theme.dark }"
            >
              <div class="s1 pl-5" :class="{ darkFont: $vuetify.theme.dark }" >
                <div class="d-flex flex-row align-center">
					<template >
					<img class="nftimg" :src="item.nftImage" alt="" />
					<div class="nftname ml-3"  :class="{ darkFont: $vuetify.theme.dark }">{{ item.nftName }}</div>
          <div class="progress" v-if=" item.status == 0 || item.status == 1 || item.status == 2">{{$t('state_progress')}}</div>
           <div class="faild" v-if="item.status == -1">{{$t('orderStateFail')}}</div>
					</template>
				
                </div>
              </div>
              <div class="s2 pl-4" :class="{ darkFont: $vuetify.theme.dark }">
                {{ item.quantity || "—" }}
              </div>

              
              <div class="s3 d-flex flex-row align-left pl-2">
                <div style="width: 100%" class="d-flex align-center">
                   <img  :src="item.fromChainIcon"  width="18" height="18" alt="">
                  <span
                    class="ml-3 item_font item_fonts"
                    :class="{ darkFont: $vuetify.theme.dark }"
                    style="font-size: 14px"
                    >{{ item.fromChainType }}</span
                  >
                </div>
              </div>

             <div class="s4 d-flex flex-row align-center pl-2">
                <div style="width: 100%" class="d-flex align-center">
                   <img  :src="item.toChainIcon"  width="18" height="18" alt="">
                  <span class="ml-3" :class="{ darkFont: $vuetify.theme.dark }">{{
                    item.toChainType
                  }}</span>
                </div>
              </div>

              <div class="s5" :class="{ darkFont: $vuetify.theme.dark }">
                {{ item.time }}
              </div>
           
            </div>
          </div>
          <div class="historyList" v-else>
          <div class="notList">{{$t("No_cross_yet")}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <v-dialog v-model="openacceptDialog">
      <acceptOffer
        v-if="openacceptDialog"
        @getOpen="OpenAccept"
        :parentValue="marketNftInfo"
        :itemInfo="itemInfo"
        :address="address"
      ></acceptOffer>
    </v-dialog> -->
  </div>
</template>

<script lang="js">
import api from "@/api";
import logoimg from "@/assets/default.png";

// import acceptOffer from "@/components/popup/acceptOffer.vue";
//   import withDraw from "@/components/popup/withDraw.vue";
  import { getFileSrc } from "@/utils/file";

const WORK_KEY = "WORK";
export default {
  name: 'offerList',
  props: {},
  components: {
    // withDraw,
    // acceptOffer
  },
  data: function() {
    return {
        currentMode:'received',
        pageSize:100,
        pageNumber:1,
        rotate:false,
         desserts:[],
         crossChianList:[],
		 marketNftInfo: {},
		openacceptDialog:false,
    }
  },
   computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
   
},
async mounted(){
    this.getCrossChainList();
    

},
 methods:{


      
  async getCrossChainList(){
    let params ={
        pageNumber:this.pageNumber,
        pageSize:this.pageSize,
	
    }
   let res =  await api.home.getCrossListByAddress(params);
   this.crossChianList = [];
    if (res.data) {

      for (let i = 0; i <  res.data.length; i++) {
        let imgUrl,nftName;
          if(!res.data[i].assetMetaDto){
            imgUrl = await getFileSrc(WORK_KEY, 'QmUn6JVu4YcVykZt9qYAbwocxPgR6Qsr6K7eb8jZbhji3u'); 
            nftName= 'No Name'
          }else{
          imgUrl = await getFileSrc(WORK_KEY, res.data[i].assetMetaDto.image);
          nftName=res.data[i].assetMetaDto.name
          }
          this.crossChianList.push({
              nftImage:imgUrl,
              nftName:nftName,
              fromChainIcon:res.data[i].fromChainIcon,
              fromChainType:res.data[i].fromChainType,
              toChainIcon:res.data[i].toChainIcon,
              toChainType:res.data[i].toChainType,
              quantity:1,
              status:res.data[i].status,
               time: this.isMobile
              ? this.timestampToDate( Number(res.data[i].timespan))
              : this.timestampToDateTime(Number(res.data[i].timespan)),
           
          });
        
      }

        // res.data.forEach( async (v) => {
        

        //   let imgUrl,nftName;
        //   if(!v.assetMetaDto){
        //     imgUrl = await getFileSrc(WORK_KEY, 'QmUn6JVu4YcVykZt9qYAbwocxPgR6Qsr6K7eb8jZbhji3u'); 
        //     nftName= 'No Name'
        //   }else{
        //   imgUrl = await getFileSrc(WORK_KEY, v.assetMetaDto.image);
        //   nftName=v.assetMetaDto.name
        //   }
        //   this.crossChianList.push({
        //       nftImage:imgUrl,
        //       nftName:nftName,
        //       fromChainIcon:v.fromChainIcon,
        //       fromChainType:v.fromChainType,
        //       toChainIcon:v.toChainIcon,
        //       toChainType:v.toChainType,
        //       quantity:1,
        //       status:v.status,
        //        time: this.isMobile
        //       ? this.timestampToDate( Number(v.timespan))
        //       : this.timestampToDateTime(Number(v.timespan)),
           
        //   });
        // });
      }

  },

//   async acceptClick(item){
//      this.itemInfo = item
// 	 let paramsInfo = {
// 	   nftAddress: this.itemInfo.nftAddress,
// 	   nftId: this.itemInfo.nftId
// 	 };
// 	 let res1 = await api.home.nftTokenInfo(paramsInfo);
// 	 this.marketNftInfo = res1.data;
	 
//      this.openacceptDialog =true
//    },
//   async calcleClick(item)
//    {
//     this.$wallet.setContractAddress(item.nftType, {
//       address: item.offerPlatformAddress,
//       platformAddress: item.offerPlatformAddress
//     });
	  
// 	      let result = await this.$wallet.cancelOffer(
// 	         item.nftType,
// 	         item.offerNumber,
// 	       )
		   
// 	      if(!result.hash){
// 	         this.isPay = false
// 	         this.$toast("error",this.$t("requireFailed"))
// 	         return
// 	      }else{
// 	      	this.isPay = false
// 	      	this.$toast("success",this.$t("success"))
// 	      	this.$emit("getOpen", false);
// 	      }
//    },
//     WithdrawBtn(){
		
		
//        this.OpenWithDraw = true
//       },
//         OpenWithDraws(e){
//         this.OpenWithDraw = e
//       },
 
//  OpenAccept(e){
//    this.openacceptDialog = e
//  },
//    refresh() {
//       this.rotate = true;
//       setTimeout(() => { this.rotate = false }, 2000);
//       this.pageNumber = 1
//        this.pageSize =50
//        this.desserts = [];
//        this.getCrossChainList();
     

//     },
 }   
}
</script>

<style lang="scss" scoped>
.contain {
  .select {
    cursor: pointer;
    width: 175px;
    height: 41px;
    background-color: #f2f1f3;
    border-radius: 10px 0px 0px 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      background-color: #7800f4;
      span {
        color: #ffffff;
      }
    }
    span {
      font-family: Helvetica;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 20px;
      letter-spacing: 0px;

      color: #270645;
    }
  }
  .past {
    cursor: pointer;
    text-align: center;
    width: 176px;
    height: 41px;
    background-color: #f2f1f3;
    border-radius: 0px 10px 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      background-color: #7800f4;
      span {
        color: #ffffff;
      }
    }
    span {
      font-family: Helvetica;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: #270645;
    }
  }
  .history {
    .Properties {
      width: 100%;
      .title {
        height: 50px;
        background-color: #ffffff;
        border-radius: 5px 5px 0 0;
        border: solid 1px #cccccc;
      }
      .line {
        //         height: 1px;
        // background-color: #ffffff;
        // border: solid 1px #cccccc;
      }
      .list {
        // min-height: 100px;
        // max-height: 250px;
        // overflow-y: auto;
        background-color: #ffffff;
        border-radius: 0 0 5px 5px;
        border: solid 1px #cccccc;
        text-align: center;
        margin-bottom: 100px;
        .titlelist {
          width: 100%;
          border-bottom: solid 0.5px #cccccc;

          height: 50px;
          font-family: Helvetica;
          font-size: 14px;
          font-stretch: normal;
          line-height: 20px;
          letter-spacing: 0px;
          color: #766983;
          .s1 {
            width: 35%;
            text-align: left;
          }
          .s2 {
            width: 15%;
            text-align: left;
          }
          .s3 {
            width: 15%;
            text-align: left;
          }
          .s4 {
            width: 15%;
            text-align: left;
          }
          .s5 {
            width: 20%;
            text-align: left;
          }
        
        }
        .historyList {
          width: 100%;
          min-height: 150px;
          .notList{
            margin-top: 60px;
  font-family: Helvetica;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 20px;
	letter-spacing: 0px;
	color: #766983;
}
          .item {

            width: 100%;
            height: 55px;
            border: solid 0.5px #cccccc;
            border-bottom: 0;
            border-left: 0;
            border-right: 0;
            font-family: Helvetica;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 20px;
            letter-spacing: 0px;
            color: #270645;
            .s1 {
       
              width: 35%;
              text-align: left;
              .nftimg {
                width: 40px;
                height: 40px;
                object-fit: cover;
                	border-radius: 5px;
              }
              .nftname {
                width: 60%;
                font-family: Helvetica;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                line-height: 20px;
                letter-spacing: 0px;
                color: #7800f4;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .faild{
                text-align: center;
            	width: 60px;
            height: 26px;
            background-image: linear-gradient(
              #ff6666, 
              #ff6666), 
            linear-gradient(
              #ffffff, 
              #ffffff);
            background-blend-mode: normal, 
              normal;
            border-radius: 13px;
                font-family: Helvetica;
                font-size: 10px;
                font-weight: bold;
                font-stretch: normal;
                line-height: 24px;
                letter-spacing: 0px;
                color: #ffffff;

              }
              .progress{
                width: 80px;
                height: 26px;
                text-align: center;
                background-image: linear-gradient(
                  #9966ff, 
                  #9966ff), 
                linear-gradient(
                  #ffffff, 
                  #ffffff);
                background-blend-mode: normal, 
                  normal;
                border-radius: 13px;
                font-family: Helvetica;
                font-size: 10px;
                font-weight: bold;
                font-stretch: normal;
                line-height: 24px;
                letter-spacing: 0px;
                color: #ffffff;
              }
            }
            .s2 {
              width: 15%;

              text-align: left;
            }
            .s3 {
              min-width: 15%;
              text-align: left;
            }
            .s4 {
              min-width: 15%;
              text-align: left;
            }
            .s5 {
              width: 20%;
              text-align: left;
            }
       
           
          }
        }
      }
    }
  }
  .refresh {
    height: 18px;
    width: 18px;
    margin-top: 5px;
    margin-left: 15px;
  }

  .go {
    transform: rotate(360deg);
    transition: all 2s;
    pointer-events: none;
    user-select: none;
  }
  &.mobile{
    .history {
  
      .Properties{
        
         .list{
             .titlelist{
              .s1{
                width: 20%;
              }
              .s2{
                width: 20%;
              }
              .s3{
                width: 20%;
              }
              .s4{
                width: 20%;
              }
              .s5{
                width: 20%;
              }
             }
            .historyList{
              overflow-x: auto;
              width: 200% ;
               .item{
                 width: 200%;
                 .s1{
                   .nftname{
                     width: 35%;
                   }
                   .progress{
                     width: 40%;
                   }
                 }
               }
            }
         }
      }
    }

  }
}
</style>

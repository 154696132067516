<template>
  <div :class="{ contain: true, mobile: isMobile }">
    <div class="user-card d-flex flex-column align-center pt-4" style="cursor: pointer;" @click.stop="click">
      <!-- <v-avatar :size="70">
      <v-img :src="value.issuerImg" alt="Midoriya" />
    </v-avatar> -->
      <Avatar
        size="116"
        :did="value.did"
        :hash="value.profilePhoto"
        imgType="especially" 
        :grade="value.grade" 
        showAvatarInfo
        :showCardList = "showCardList"
      />
      <!-- <div class="d-flex flex-column ml-4"> -->
        <div class="name font-weight-medium mt-3">{{ value.userName | didfilter}}</div>
        <!-- <div v-if="!isMobile" class=" address text-caption">{{ value.issuerAddr| didfilter }}</div>
        <div v-if="isMobile" class="text-caption">
          {{ value.issuerAddr | didfilter }}
        </div> -->
      <!-- </div> -->
      <div class=" num font-weight-medium">{{ value.workCount }}</div>

      <v-dialog class="px-50" v-model="dialog" width="1100">
        <v-card
          id="box"
          class="px-10 overflow-auto"
          height="800"
          style="padding-top: 90px; position: relative"
        >
          <v-icon
            color="primary"
            style="top: 16px; right: 19px; position: absolute"
            size="30"
            @click="dialog = false"
            >mdi-close-circle</v-icon
          >
          <!-- <Cards :cards="cards" /> -->
          <CardList
            :list="list"
            v-scroll:#box="onScroll"
            type="usercard"
            :owerfrom="owerfrom"
            :isMinPrice="isMinPrice"
          />
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script lang='js'>
  // import Cards from "@/views/home/components/Cards.vue";
  import CardList from "@/components/workCard/CardList.vue";
  import Avatar from '@/components/avatar/index.vue';
  import api from "@/api"


export default {
  name: 'UserCard',
    components: {  CardList,Avatar},
  props: {
      value: Object,
	  owerfrom: String,
    isMinPrice:String
  },
  data: function() {
    return {
      dialog: false,
       list: [],
       pageNumber: 0,
       pageSize: 24,
       totalPage: false,
       showCardList:true
    };
  },
  filters: {
  	didfilter: function(value) {
		if(value && value.length > 12) {
			return value.substr(0,6)+"..."+value.substr(-6);
		} else {
			return value;
		}
  	}
  },
  mounted(){
    console.log("wxl -- 77",this.value);

  },
  computed: {
         isMobile: function () {
            return this.$vuetify.breakpoint.mobile;
         },
      },
  methods: {
   click: function() {
      this.pageNumber = 0;
      this.dialog =true;
      this.getList()
    },
    async getList(){
      this.pageNumber++;
      let params = {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
            owner: this.$store.state.did,
           
            byCreator:this.value.did
         };
         let res = await api.home.getNftOwn(params);
         let {list, totalPage} = res.data;
         this.totalPage = totalPage;
         this.list = this.list.concat(list);
    },
    onScroll(e) {
         const {scrollTop, clientHeight, scrollHeight} =
           e.target;
         if (scrollTop + clientHeight + 10 >= scrollHeight && (this.totalPage > this.pageNumber)) {
            this.getList();
         }
      },
  }
};

</script>

<style lang='scss' scoped>
.contain {
  height: auto;
  .user-card {
  
     cursor: pointer;
      width: 180px;
      height: 206px;
      background-image: linear-gradient(#ffffff, #ffffff),
        linear-gradient(#6f58d9, #6f58d9);
      background-blend-mode: normal, normal;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
      border-radius: 5px;
      margin-right: 22px;
      margin-top: 20px;

      &:nth-child(6n) {
        margin-right: 0;
      }
       .num {
      font-family:Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      width: 100%;
      text-align: center;
    }
    .name {
      width: 100%;
      text-align: center;
      font-family:Helvetica;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }
    }
  
  &.mobile{
    width:100%;
  }
}
</style>

<template>
  <div class="contain" >
    <v-card  :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        src="@/assets/icons/btn_close.png"
        alt=""
      />
      <div  class="contant "  >
        <span class="title">{{ $t("choose_folder") }}</span>
        <div class="nftcard d-flex flex-lg-row flex-wrap" ref="box" v-scroll="onScroll1">
          <CollectionCard
            class="indexfan d-flex flex-lg-row flex-wrap"
            v-for="(item, i) in list"
            :key="i"
            :value="item"
            :mycreate="mycreate"
            type="createorFolder"
            :nftList="nftList"
          />
          <div class="selsect">
            <div class="box">
              <v-text-field
               ref="nameValue"
                :rules="nameRules"
      
              class="mt-4 ml-4"
                v-model="nameValue"
               maxlength="80"
                outlined
                placeholder="名称"
              ></v-text-field>
               <span style="position: relative;top:-102px;right:-220px">{{this.nameValue.length}}/{{this.titleMaxLength}} </span>
              <v-icon
                class="icon"
                style="font-size: 60px; margin-top: 30px; margin-left: 70px"
                color="#e3e3e3"
                >mdi-plus</v-icon
              >
              <v-btn class="sub" submit @click="subCommit">{{
                $t("create_folder_quick")
              }}</v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <PromptBox ref="promptBox"></PromptBox>
    <uComponents ref="ucom"></uComponents>
  </div>
</template>

<script>
import api from "@/api";
// import { getFileSrc } from "@/utils/file";
import PromptBox from "@/components/PromptBox.vue";
// const NFT_KEY = "CARDNFT";
import CollectionCard from "@/components/workCard/CollectionCard.vue";
export default {
  components: { CollectionCard, PromptBox },
  data: () => ({
    list: [],
    pageNumber: 0,
    pageSize: 20,
    mycreate: "mycreate",
    nameValue: "",
    totalPage:0,
    titleMaxLength: 80,
     nameRules: [
      (v) => (!!v) || "Name must be less than 80 characters",
    ],
  }),
  props: {
    nftList:{
      type:Array
    }
  },

  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  async mounted() {
    this.getList();
     this.$refs.box.addEventListener('scroll',this.Scrollbottom);
     console.log("9999999",this.nftList)
    
  },
  methods: {

      verify() {
     
      let nameVer = this.$refs.nameValue.validate(true);
      
     
      !nameVer ? this.$refs.nameValue.focus() : '';
     
      return nameVer ;
    },
    Scrollbottom () { 
		let scrollTop = this.$refs.box.scrollTop || this.$refs.box.scrollTop;
		let clientHeight = this.$refs.box.clientHeight;
		let scrollHeight = this.$refs.box.scrollHeight;
		if (scrollTop + clientHeight >= scrollHeight &&
        this.totalPage > this.pageNumber) {
        console.log("滚动到底部了")
        this.getList();
	    }
  },


    async getList() {
      this.pageNumber++;
      // let params = {
      //   pageSize: this.pageSize,
      //   pageNumber: this.pageNumber,
      //   owner: this.$store.state.did,
      //   // sort: this.activeSort,
      //   // attribute: index,
      //   chainType: "IRIS_IRISHUB",
      //   issuerAddr: "iaa15k328z4v65qd7tp82fcvrymfmfn6lnkq5ep30c",
      // };
        let params = {
            collectionType:2,
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
            chainType:this.$store.state.chainType,
             did:this.$store.state.did,
             owner:this.$store.state.did,
             filterType:12
         };
        let  res = await api.home.getOwnByCollection(params);

      let list = res.data.list;
      let totalPage = res.data.totalPage;
      this.totalPage = totalPage;
      this.list = this.list.concat(list);
    },
    onScroll1(e) {
      console.log(e)
      const { scrollTop, clientHeight, scrollHeight } = e.target.scrollingElement;
      //console.log("ssss",scrollTop,clientHeight,scrollHeight,scrollTop + clientHeight)
      if (
        scrollTop + clientHeight + 10 >= scrollHeight &&
        this.totalPage > this.pageNumber
      ) {
        this.getList();
      }
    },

    //关闭弹框，发送open值
    closeDialog() {
      this.$emit("getOpen", false);
    },
    async subCommit() {
       if(!this.verify()) {
            return;
      }
      let timestamp = Date.now();
      console.log(timestamp)
      let params={
        attribute:3,
        collectionImgUrl:"Qmastxs5KogKvSKJzBJPdC4uQpBRwy1JzXyfmsmQ1s1LZJ",
        name:this.nameValue,
        linkUrl:timestamp
      }
      let res = await api.home.createFolder(params)
      console.log(res)
      if(res.success == true)
      {
         this.$toast("success", this.$t('amCreateSuccessTitle'));
      }else
      {
        this.$toast("error", res.msg);
      }
      setTimeout(()=>{
          this.list = [];
          this.pageNumber = 0
          this.getList();
          this.nameValue = ''
      },2000)
    
    },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  overflow: hidden ;
  // &::v-deep .v-card {
   
  //   overflow: hidden;
  // }
  
  .card {
   
    width: 1000px;
    background-image: linear-gradient(#ffffff, #ffffff),
      linear-gradient(#6f58d9, #6f58d9);
    background-blend-mode: normal, normal;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
    .clo {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 30px;
      height: 31px;
      background-color: #fff;
      border-radius: 50%;
      margin-right: 0;
      cursor: pointer;
    }
    .contant {
      margin-left: 41px;
      .nftcard {
        padding: 0 0 48px;
        height: 500px;
        overflow:scroll;
        .indexfan {
          cursor: pointer;
          width: 281px;
          height: 281px;
          background-image: linear-gradient(#ffffff, #ffffff),
            linear-gradient(#6f58d9, #6f58d9);
          background-blend-mode: normal, normal;
          box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
          border-radius: 5px;
          margin-right: 26px;
          margin-top: 20px;

          &:nth-child(3n) {
            margin-right: 0;
          }
        }
        .selsect {
          margin-top: 20px;
          border: 2px dashed #e3e3e3;
          height: 281px;
          width: 281px;
          display: inline-block;
          .box {
               &::v-deep  .v-input__slot {
                height:46!important;
                width:250px!important
            }
              &::v-deep .v-text-field input{
                max-width: 80%;
              }
            .sub {
              margin: 31px 35px;
              width: 214px;
              height: 41px;
              background-image: linear-gradient(
                  90deg,
                  #d300fd 0%,
                  #a439fe 26%,
                  #7471ff 52%,
                  #00fdcf 100%
                ),
                linear-gradient(#270645, #270645);
              background-blend-mode: normal, normal;
              border-radius: 25px;
              opacity: 0.9;
              font-size: 15px;
              font-weight: bold;
              color: #ffffff;
            }
          }
        }
      }
      .title {
        width: 129px;
        font-family: "\5FAE\8F6F\96C5\9ED1";
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 50px;
        letter-spacing: 0px;
        color: #270645;
      }
    }
  }
}
</style>

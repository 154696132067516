<template>
  <div class="work" >
    <div  v-if="!videoUrl && !isCanplay">
      <div v-if="createFrom =='collection'">
      <v-img
     
      :src="src"
      :contain="contain"
      width="100%"
      max-height="100%"
      :aspect-ratio="aspectRatio === -1 ? undefined : aspectRatio"
      @click="imgClick"
      style="cursor: pointer; display: flex; align-items: center;background-color: rgb(39, 6, 69);"
    >
      <div class="content">
        <v-icon v-if="denomType === 7" class="icon" size="30" color="#fff"
          >mdi-video</v-icon
        >
      </div>
      <div
        style="
         background-color: rgb(39, 6, 69,0.3);;
          height: 286px;
          padding-top: 15px;
        "
      >
        <div class="infos d-flex flex-row">
          <div class="d-flex flex-column contant" style="position: relative">
            <div class="name">{{ value.collectionName }}</div>
            <span v-if="value.nftCount != null">
              {{ value.nftCount }} {{ $t("item") }}</span
            >
          </div>
          <div v-if="(mycreate != 'mycreate' || mycreate == 'otherpage' )">
            <div v-if="value.collectionType == 1 && createFrom != 'collection'">
              <img
                class="ml-11 mt-1"
                src="@/assets/icons/icon_collection.png"
                width="20px"
                height="20px"
                alt=""
                @mouseenter="moused()"
                @mouseleave="leave()"
              />
              <div class="explain d-flex flex-row align-center" v-if="isshow">
                <span>{{ $t("nft_explain") }}</span>
              </div>
            </div>
            <div v-else-if="value.collectionType == 2 && createFrom != 'collection'">
              <img
                class="ml-11 mt-1"
                src="@/assets/icons/icon_folder.png"
                width="25px"
                height="20px"
                alt=""
                @mouseenter="moused()"
                @mouseleave="leave()"
              />
              <div class="explain d-flex flex-row align-center" v-if="isshow">
                <span>{{ $t("collection_explain") }}</span>
              </div>
            </div>
          </div>
          <div v-else>
            <img
              v-if="value.isBurn"
              class="ml-11 mt-1"
              src="@/assets/icons/icon_delete.png"
              width="20px"
              height="20px"
              alt=""
              @click.stop="burnBtn"
            />
          </div>
          
        </div>
        <div
          class="pb-0 px-5 text-avatar1 d-flex justify-center"
         v-if="createFrom != 'collection'"
        >
        <div  v-if="(mycreate != 'mycreate' && mycreate != 'otherpage')">
          <Avatar
            size="50"
            :did="value.owner"
            :hash="value.profilePhoto || value.issuerImg"
            imgType="middlev"
            :grade="value.grade"
            showAvatarInfo
            :showCardList="showCardList"
          />
        </div>
         <div
          class="pb-0 px-5 text-avatar"
          style="margin-top: 200px;"
          v-else
        ></div>
          
         
          <div>
           <img
          v-if=" value.disabled==3"
            class=""
            src="@/assets/icons/icon_tan_w.png"
            width="20px"
            height="20px"
            alt=""
            @mouseenter="mousedcomplain()"
            @mouseleave="leavecomplain()"
            
          />
          <div class="complain d-flex flex-row align-center" v-if="isshowcomplain">
            {{ $t("nft_restricted") }}
          </div>
          </div>
        </div>

         <div
          class="pb-0 px-5 text-avatar d-flex justify-center"
          v-else
        >
        <div  v-if="(mycreate != 'mycreate' && mycreate != 'otherpage')">
          <Avatar
            size="50"
            :did="value.owner"
            :hash="value.profilePhoto || value.issuerImg"
            imgType="middlev"
            :grade="value.grade"
            showAvatarInfo
            :showCardList="showCardList"
          />
        </div>
         <div
          class="pb-0 px-5 text-avatar"
          style="margin-top: 200px;"
          v-else
        ></div>
          
         
          <div>
           <img
          v-if=" value.disabled==3"
            class=""
            src="@/assets/icons/icon_tan_w.png"
            width="20px"
            height="20px"
            alt=""
            @mouseenter="mousedcomplain()"
            @mouseleave="leavecomplain()"
            
          />
          <div class="complain d-flex flex-row align-center" v-if="isshowcomplain">
           {{ $t("nft_restricted") }}
          </div>
          </div>
        </div>
       
      </div>
      
    </v-img>
      </div>
      
      <div v-else>
          <v-img
     v-if="!isMobile"
      :src="src"
      :contain="contain"
      width="100%"
      max-height="100%"
      :aspect-ratio="aspectRatio === -1 ? undefined : aspectRatio"
      @click="imgClick"
      style="cursor: pointer; display: flex; align-items: center"
    >
      <div class="content">
        <v-icon v-if="denomType === 7" class="icon" size="30" color="#fff"
          >mdi-video</v-icon
        >
      </div>
      <div
        style="
          background-color: rgb(39, 6, 69, 0.3);
          height: 286px;
          padding-top: 15px;
        "
      >
        <div class="infos d-flex flex-row">
          <div class="d-flex flex-column contant" style="position: relative">
            <div class="name" v-if="(mycreate != 'mycreate')">{{ value.collectionName }}</div>
			<div class="name" v-if="(mycreate == 'mycreate')">{{ value.name }}</div>
            <span v-if="value.nftCount != null">
              {{ value.nftCount }} {{ $t("item") }}</span
            >
          </div>
          <div v-if="(mycreate != 'mycreate' || mycreate == 'otherpage' )">
            <div v-if="value.collectionType == 1 && createFrom != 'collection'">
              <img
                class="ml-11 mt-1"
                src="@/assets/icons/icon_collection.png"
                width="20px"
                height="20px"
                alt=""
                @mouseenter="moused()"
                @mouseleave="leave()"
              />
              <div class="explain d-flex flex-row align-center" v-if="isshow">
                <span>{{ $t("nft_explain") }}</span>
              </div>
            </div>
            <div v-else-if="value.collectionType == 2 && createFrom != 'collection'">
              <img
                class="ml-11 mt-1"
                src="@/assets/icons/icon_folder.png"
                width="25px"
                height="20px"
                alt=""
                @mouseenter="moused()"
                @mouseleave="leave()"
              />
              <div class="explain d-flex flex-row align-center" v-if="isshow">
                <span>{{ $t("collection_explain") }}</span>
              </div>
            </div>
          </div>
          <div v-else>
            <img
              v-if="value.isBurn"
              class="ml-11 mt-1"
              src="@/assets/icons/icon_delete.png"
              width="20px"
              height="20px"
              alt=""
              @click.stop="burnBtn"
            />
          </div>
          
        </div>
        <div
          class="pb-0 px-5 text-avatar1 d-flex justify-center"
         v-if="createFrom != 'collection'"
        >
        <div  v-if="(mycreate != 'mycreate' && mycreate != 'otherpage')">
          <Avatar
            size="50"
            :did="value.owner"
            :hash="value.profilePhoto || value.issuerImg"
            imgType="middlev"
            :grade="value.grade"
            showAvatarInfo
            :showCardList="showCardList"
          />
        </div>
         <div
          class="pb-0 px-5 text-avatar"
          style="margin-top: 200px;"
          v-else
        ></div>
          
         
          <div>
           <img
          v-if="value.disabled==3"
            class=""
            src="@/assets/icons/icon_tan_w.png"
            width="20px"
            height="20px"
            alt=""
            @mouseenter="mousedcomplain()"
            @mouseleave="leavecomplain()"
            
          />
          <div class="complain d-flex flex-row align-center" v-if="isshowcomplain">
           {{ $t("nft_restricted") }}
          </div>
          </div>
        </div>

         <div
          class="pb-0 px-5 text-avatar d-flex justify-center"
          v-else
        >
        <div  v-if="(mycreate != 'mycreate' && mycreate != 'otherpage')">
          <Avatar
            size="50"
            :did="value.owner"
            :hash="value.profilePhoto || value.issuerImg"
            imgType="middlev"
            :grade="value.grade"
            showAvatarInfo
            :showCardList="showCardList"
          />
        </div>
         <div
          class="pb-0 px-5 text-avatar"
          style="margin-top: 200px;"
          v-else
        ></div>
          
         
          <div>
           <img
          v-if=" value.disabled==3"
            class=""
            src="@/assets/icons/icon_tan_w.png"
            width="20px"
            height="20px"
            alt=""
            @mouseenter="mousedcomplain()"
            @mouseleave="leavecomplain()"
            
          />
          <div class="complain d-flex flex-row align-center" v-if="isshowcomplain">
           {{ $t("nft_restricted") }}
          </div>
          </div>
        </div>
       
      </div>
      
    </v-img>
          <v-img
     v-else
      :src="src"
      :contain="contain"
      width="100%"
      max-height="100%"
      :aspect-ratio="aspectRatio === -1 ? undefined : aspectRatio"
      @click="imgClick"
      style="cursor: pointer; display: flex; align-items: center"
    >
      <div class="content">
        <v-icon v-if="denomType === 7" class="icon" size="30" color="#fff"
          >mdi-video</v-icon
        >
      </div>
      <div
        style="
          background-color: rgb(39, 6, 69, 0.3);
          height: 330px;
          width:100%;
          padding-top: 15px;
        "
      >
        <div class="infos d-flex flex-row">
          <div class="d-flex flex-column contant" style="position: relative">
            <div class="name" v-if="(mycreate != 'mycreate')">{{ value.collectionName }}</div>
			<div class="name" v-if="(mycreate == 'mycreate')">{{ value.name }}</div>
            <span v-if="value.nftCount != null">
              {{ value.nftCount }} {{ $t("item") }}</span
            >
          </div>
          <div v-if="(mycreate != 'mycreate' || mycreate == 'otherpage' )">
            <div v-if="value.collectionType == 1 && createFrom != 'collection'">
              <img
                class="ml-16 mt-1"
                src="@/assets/icons/icon_collection.png"
                width="20px"
                height="20px"
                alt=""
                @mouseenter="moused()"
                @mouseleave="leave()"
              />
              <div class="explain d-flex flex-row align-center" v-if="isshow">
                <span>{{ $t("nft_explain") }}</span>
              </div>
            </div>
            <div v-else-if="value.collectionType == 2 && createFrom != 'collection'">
              <img
                class="ml-16 mt-1"
                src="@/assets/icons/icon_folder.png"
                width="25px"
                height="20px"
                alt=""
                @mouseenter="moused()"
                @mouseleave="leave()"
              />
              <div class="explain d-flex flex-row align-center" v-if="isshow">
                <span>{{ $t("collection_explain") }}</span>
              </div>
            </div>
          </div>
          <div v-else>
            <img
              v-if="value.isBurn"
              class="ml-11 mt-1"
              src="@/assets/icons/icon_delete.png"
              width="20px"
              height="20px"
              alt=""
              @click.stop="burnBtn"
            />
          </div>
          
        </div>
        <div
          class="pb-0 px-5 text-avatar1 d-flex justify-center"
         v-if="createFrom != 'collection'"
        >
        <div  v-if="(mycreate != 'mycreate' && mycreate != 'otherpage')">
          <Avatar
            size="50"
            :did="value.owner"
            :hash="value.profilePhoto || value.issuerImg"
            imgType="middlev"
            :grade="value.grade"
            showAvatarInfo
            :showCardList="showCardList"
          />
        </div>
         <div
          class="pb-0 px-5 text-avatar"
          style="margin-top: 200px;"
          v-else
        ></div>
          
         
          <div>
           <img
          v-if="value.disabled==3"
            class=""
            src="@/assets/icons/icon_tan_w.png"
            width="20px"
            height="20px"
            alt=""
            @mouseenter="mousedcomplain()"
            @mouseleave="leavecomplain()"
            
          />
          <div class="complain d-flex flex-row align-center" v-if="isshowcomplain">
           {{ $t("nft_restricted") }}
          </div>
          </div>
        </div>

         <div
          class="pb-0 px-5 text-avatar d-flex justify-center"
          v-else
        >
        <div  v-if="(mycreate != 'mycreate' && mycreate != 'otherpage')">
          <Avatar
            size="50"
            :did="value.owner"
            :hash="value.profilePhoto || value.issuerImg"
            imgType="middlev"
            :grade="value.grade"
            showAvatarInfo
            :showCardList="showCardList"
          />
        </div>
         <div
          class="pb-0 px-5 text-avatar"
          style="margin-top: 200px;"
          v-else
        ></div>
          
         
          <div>
           <img
          v-if=" value.disabled==3"
            class=""
            src="@/assets/icons/icon_tan_w.png"
            width="20px"
            height="20px"
            alt=""
            @mouseenter="mousedcomplain()"
            @mouseleave="leavecomplain()"
            
          />
          <div class="complain d-flex flex-row align-center" v-if="isshowcomplain">
           {{ $t("nft_restricted") }}
          </div>
          </div>
        </div>
       
      </div>
      
    </v-img>
      </div>
    
    </div>
    <div  v-else>
       <video
     
      :src="videoUrl"
      :contain="contain"
      :aspect-ratio="aspectRatio === -1 ? undefined : aspectRatio"
      @click="imgClick"
      style="cursor: pointer; width: 100%; max-height: 100%"
      controls
      :poster="src"
      @canplaythrough="canplaythrough"
      autoplay
      loop
      muted
    ></video>
    </div>
   
   

    <!-- 销毁 -->
    <v-dialog v-model="Burning">
      <burn
        :address="this.value.address"
        :contractCollectionIds="this.value.id"
        :type="type"
        @getOpen="openBurn"
        :value="this.value"

      ></burn>
    </v-dialog>
  </div>
</template>

<script>
import Burn from "@/components/popup/burn.vue";
import { getFileSrc } from "@/utils/file";
import Avatar from "@/components/avatar/index.vue";

const COLLECTION_KEY = "COLLECTION";
export default {
  name: "Work",
  inheritAttrs: false,
  components: { Avatar, Burn },
  props: {
    // -1 处理不设置的情况
    aspectRatio: {
      type: Number,
      default: 1,
    },
    hash: {
      type: String,
    },
    hashVideo: {
      type: String,
    },
    denomType: Number, // 7 video
    contain: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
    },
    mycreate: {
      type: String,
    },
    
    createFrom: {
      type: String,
    },
  },
  data: () => ({
    type: "collectionNFT",
    contractCollectionIds:'',
    src: undefined,
    videoUrl: undefined,
    videoOptions: {},
    isCanplay: false,
    isshow: false,
    isshowcomplain: false,
    showCardList: true,
    Burning: false,
  }),
  computed: {
        isMobile: function () {
         return this.$vuetify.breakpoint.mobile;
      },
     
      },
   
  watch: {
    hash: {
      handler: async function (hash) {
        if (hash) {
          this.src = await getFileSrc(COLLECTION_KEY, hash);
        }
      },
      immediate: true,
    },
    hashVideo: {
      handler: async function (hash) {
        if (hash) {
          this.videoUrl = await getFileSrc(COLLECTION_KEY, hash, true);
        }
      },
      immediate: true,
    },
  },
  mounted(){
    if(this.value.collectionType ==1)
    {
      this.type="collectionNFT"
    }else{
      this.type="folderNFT"

    }
  },
  methods: {
    imgClick() {
      this.$emit("imgClick");
    },
    canplaythrough() {
      this.isCanplay = true;
    },
    moused() {
      console.log("33333333");
      this.isshow = true;
    },
    leave() {
      this.isshow = false;
    },
    mousedcomplain() {
      this.isshowcomplain = true;
    },
    leavecomplain() {
       this.isshowcomplain = false;
    },
    //销毁弹层
    burnBtn() {
      this.Burning = true;
    },
    openBurn(e) {
      this.Burning = e;
    },
  },
};
</script>

<style lang="scss" scoped>
.work {
  .content {
    width: 287px;
    height: 287px;
    position: relative;
    height: 100%;
    .icon {
      position: absolute;
      left: 15px;
      bottom: -260px;
    }
  }
  

  .infos {
    //  margin-top: -115px;
    .contant {
      margin-left: 26px;
      .name {
        width: 175px;
        // height: 22px;
        font-family:Helvetica;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 24px;
        letter-spacing: 0px;
        color: #ffffff;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      span {
        min-width: 29px;
        height: 16px;
        font-family:Helvetica;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 24px;
        letter-spacing: 0px;
        color: #ffffff;
      }
    }
    .explain {
      width: 135px;
      height: 49px;
      background-color: #270645;
      border-radius: 3px;
      position: absolute;
      top: 55px;
      right: 15px;
      span {
        font-family:Helvetica;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 15px;
        letter-spacing: 0px;
        color: #ffffff;
        margin-left: 13px;
        margin-right: 13px;
      }
    }
  }
  .text-avatar{
    margin-top: 180px;
     margin-left: 35%
     position：relative;
    img {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 20px;
    bottom: 25px;
  }
  .complain {
    width: 130px;
    min-height: 30px;
    background-color: #270645;
    border-radius: 3px;
    position: absolute;
    bottom: 4px;
    right: 42px;
    font-family:Helvetica;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 15px;
    letter-spacing: 0px;
    color: #ffffff;
    padding-left: 15px;
    padding-right: 15px;
  }
  }
  .text-avatar1{
    margin-top: 150px;
     margin-left: 35%
     position：relative;
    img {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 20px;
    bottom: 25px;
  }
  .complain {
    width: 130px;
    min-height: 30px;
    background-color: #270645;
    border-radius: 3px;
    position: absolute;
    bottom: 4px;
    right: 42px;
    font-family:Helvetica;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 15px;
    letter-spacing: 0px;
    color: #ffffff;
    padding-left: 15px;
    padding-right: 15px;
  }
  }
}
</style>

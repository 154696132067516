


<template>
  <div :class="{ mobile: isMobile }">
    <div class="switch d-flex justify-space-between">
      <div class="image" v-if="activeSort != 5"> 
        <div class="top_tab d-flex flex-row  mb-4">
             <span class="btn mr-8" :class="{ btn2: ownerClick }" v-if="ownerClick && isShowCheckbox == 'isCreateCheck'" @click="mode_click('owned')"
            >{{ $t("Owned") }}</span
          > 
           <span class="btn mr-8" :class="{ btn2: ownerClick }" v-else-if=" isShowCheckbox != 'isCreateCheck'" @click="mode_click('owned')"
            >{{ $t("Owned") }}</span
          > 
             <span class="btn mr-8" :class="{ btn2: soldClick }" v-if="soldClick && isShowCheckbox == 'isCreateCheck'" @click="mode_click('sold')"
            >{{ $t("Sold") }}</span
          > 
            <span class="btn mr-8" :class="{ btn2: soldClick }" v-else-if="  isShowCheckbox != 'isCreateCheck'" @click="mode_click('sold')"
            >{{ $t("Sold") }}</span
          > 
           <span class="btn mr-8" :class="{ btn2: collectionClick }" v-if="isShowCheckbox != 'isCreateCheck'" @click="mode_click('collection')"
            >{{ $t("Collections") }}</span
          > 
            <span class="btn mr-8" :class="{ btn2: folderClick }" v-if="isShowCheckbox != 'isCreateCheck'" @click="mode_click('folder')"
            >{{ $t("Folder") }}</span
          > 
           <div class="btn " :class="{ btn2: crossChainClick }" v-if="isShowCheckbox != 'isCreateCheck'" @click="mode_click('crossChain')"
            >{{ $t("Cross_record") }}</div
            >
          
        </div>
      </div>
      <div class="image" v-else></div>
      <div class="select d-flex flex-row" >
          <button class="subOnsale mr-3 " rounded @click="addToFolder"  v-if="showModel != 'collection' && showModel != 'folder' && showModel != 'crossChain' && isShowCheckbox != 'isCreateCheck'">
                <div class="titleName">{{ $t("create_nft_type2") }}</div>
          </button>
        <SortSelect
        v-if="showModel != 'folder' && showModel != 'crossChain' && isShowCheckbox != 'isCreateCheck'"
          class="mr-1 dot mt-lg-1 ml-15"
          :list="selectList"
          v-model="activeSort"
          style="z-index:100 !important"
      
        />
        <img
        v-if="isShowCheckbox != 'isCreateCheck' && showModel != 'crossChain'"
          :class="[rotate ? 'go' : '']"
          class="refresh"
          src="@/assets/icons/icon_refresh.png"
          @click="refresh"
        />
      </div>
    </div>
    <div class="explain">
         <div class="nftexplain d-flex flex-row align-center" v-if="isshownft" >
                <span>{{ $t("on_chain_nfts") }}</span>
          </div>
          <div class="collectexplain d-flex flex-row align-center"  v-if="isshowcollect">
                <span>{{ $t("on_chain_Collections") }}</span>
              </div>
    </div>
   

    <v-window v-model="activeSort"  v-if="showModel != 'collection' && showModel != 'folder' && showModel != 'crossChain'" >
       <v-window-item value="1">
        <div v-if="empty" class="empty">{{ $t("EmptyHere") }}</div>
        <div class="cards-container mt-4">
        
             <CardList
            :list="list"
            v-scroll="onScroll"
            owerfrom="personalPage"
            :type="showModel == 'owned'? 'clearmode': 'my'"
             :isShowCheckbox="isShowCheckbox"  
             :checkAllCard="checkAllCard"  
             @getCardList="getNftLists"  
          />       
          <loading :isShowLoading="isShowLoading"></loading>
        </div>
      </v-window-item>
      <v-window-item value="0">
        <div v-if="empty" class="empty">{{ $t("EmptyHere") }}</div>
        <div class="cards-container">
          <CardList
            :list="list"
            v-scroll="onScroll"
            owerfrom="personalPage"
           :type="showModel == 'owned'? 'clearmode': 'my'"
            :isShowCheckbox="isShowCheckbox" 
            :checkAllCard="checkAllCard"   
             @getCardList="getNftLists"  
               @clickCheck="clickCheck"
          />
          <loading :isShowLoading="isShowLoading"></loading>
        </div>
      </v-window-item>
      <v-window-item value="2">
        <div v-if="empty" class="empty">{{ $t("EmptyHere") }}</div>
        <div class="cards-container">
          <CardList
            :list="list"
            v-scroll="onScroll"
           :type="showModel == 'owned'? 'clearmode': 'my'"
            owerfrom="personalPage"
             :isShowCheckbox="isShowCheckbox"  
             :checkAllCard="checkAllCard"  
             @getCardList="getNftLists" 
               @clickCheck="clickCheck" 
          />
          <loading :isShowLoading="isShowLoading"></loading>
        </div>
      </v-window-item>
       <v-window-item value="3">
        <div v-if="empty" class="empty">{{ $t("EmptyHere") }}</div>
        <div class="cards-container">
          <CardList
            :list="list"
            v-scroll="onScroll"
            owerfrom="personalPage"
            :type="showModel == 'owned'? 'clearmode': 'my'"
             :isShowCheckbox="isShowCheckbox"  
             :checkAllCard="checkAllCard"  
             @getCardList="getNftLists" 
               @clickCheck="clickCheck" 
          />
          <loading :isShowLoading="isShowLoading"></loading>
        </div>
      </v-window-item>
       <v-window-item value="4">
        <div v-if="empty" class="empty">{{ $t("EmptyHere") }}</div>
        <div class="cards-container">
          <CardList
            :list="list"
            v-scroll="onScroll"
            owerfrom="personalPage"
            :type="showModel == 'owned'? 'clearmode': 'my'"
             :isShowCheckbox="isShowCheckbox"
             :checkAllCard="checkAllCard" 
             @getCardList="getNftLists"  
               @clickCheck="clickCheck"
          />
          <loading :isShowLoading="isShowLoading"></loading>
        </div>
      </v-window-item>
      <v-window-item value="5">
        <div v-if="empty" class="empty">{{ $t("EmptyHere") }}</div>
        <div class="d-flex flex-wrap user-list" style="padding-left:60px;">
          <UserCard
            class="mb-3 user-card"
            v-for="(item, i) in users"
            :key="i"
            :value="item"
            owerfrom="personalPage"
            isMinPrice="lastprice"
          />
        </div>
        <loading :isShowLoading="isShowLoading"></loading>
      </v-window-item>
    </v-window>

    <div class="collect" v-if="showModel == 'collection'">
   
      <div class="createCollect" @click="createCollection"> 
        <img src="@/assets/icons/create_collection.png" alt="" >
        <div class="createTitle">{{ $t("create_collection") }}</div>
        <div class="des">{{ $t("create_collection_des") }}</div>
      </div>
      <div class="card" v-for="(item, index) in list" :key="index">
        <collect-card
         v-scroll="onScroll"
          :pageValue="item"
          :hash="item.imgUrl"
          :did="did"
          type="createorPage"
          collectsource="createCollect"
          showAvatar
        >
        <v-btn class="uc createnft" v-if="item.owner == did" width="150px" height="41px" rounded @click="createNft(item)">+ {{ $t("create_nft") }}</v-btn>
        <img
                v-if="item.owner == did"
                class="ml-5 edit"
                src="@/assets/icons/icon_edit.png"
                width="25px"
                height="25px"  
                @click="editCollection(item)"
                alt=""
                @mouseenter="moused(item.id)"
                 @mouseleave="leave()"
                
            />
           <div class="editExplain d-flex flex-row align-center"  v-if="isshow &&  share_id == item.id ">
                <span>{{ $t("edit_collection") }}</span>
            </div>

        </collect-card>
        
      </div>
             <loading :isShowLoading="isShowLoading" style="padding-left:300px"></loading>
    </div>
      <div class="collect" v-else-if="showModel == 'folder'">
      <div class="createCollect" @click="createFolder"> 
        <img src="@/assets/icons/create_folder.png" alt="" >
        <div class="createTitle">{{ $t("add_folder") }}</div>
        <div class="des">{{ $t("create_folder_des") }}</div>
      </div>
      <div class="card" v-for="(item, index) in list" :key="index">
        <collect-card
         v-scroll="onScroll"
          :pageValue="item"
          :hash="item.imgUrl"
          :did="did"
          type="createorPage"
          collectsource="createFolder"
          showAvatar
        >
        <!-- <v-btn class="uc createnft" v-if="item.owner == did" width="150px" height="41px" rounded @click="createNft(item)">+ {{ $t("create_nft") }}</v-btn> -->
        <img
                v-if="item.owner == did && item.nftCount == 0"
                class="ml-5 burn"
                src="@/assets/icons/icon_delete1.png"
                width="25px"
                height="25px"  
                @click="burnFolder(item)"
                alt=""
                @mouseenter="mousedBurn(item.id)"
                 @mouseleave="leaveBurn()"
                
            />
           <div class="editBurn d-flex flex-row align-center"  v-if="isshowBurn &&  shareBurn_id == item.id ">
                <span>{{ $t("delete_folder")}}</span>
            </div>
        <img
                v-if="item.owner == did"
                class="ml-5 edit"
                src="@/assets/icons/icon_edit.png"
                width="25px"
                height="25px"  
                @click="editFolder(item)"
                alt=""
                @mouseenter="moused(item.id)"
                 @mouseleave="leave()"
                
            />
            
           <div class="editExplain d-flex flex-row align-center"  v-if="isshow &&  share_id == item.id ">
                <span>{{ $t("edit_folder") }}</span>
            </div>
            
        </collect-card>
        
      </div>
  <loading :isShowLoading="isShowLoading" style="padding-left:300px"></loading>
      
           
    </div>
    <div v-else-if="showModel == 'crossChain' && !isShowLoading">
        <CrossChain />
    </div>
    <div
           v-if="isShowCheckbox == 'isCreateCheck'"
          class="totalbox d-flex justify-end align-center"
        >
         <v-checkbox
            class="check mt-2"
            color="#ffffff"
      :label="label"
            hide-details
       @click="checkboxChange"
       :value="checkAllCard"
          ></v-checkbox>
          <p> {{ $t("nft_number") }}：{{ totalNumber }}</p>
          <div class="btnbox mr-5">
            <button class="subcancel" @click="subCancel" >{{ $t("orderActionCancel") }}</button>
          </div>
          <button class="subsure " @click="subSure">{{ $t("countersign") }}</button>
        </div>
     
     

              <!-- 被投诉提醒框 -->
    <v-dialog v-model="Banprompt">
      <banprompt v-if="Banprompt"  @getOpenBan="openBanprompt"></banprompt>
    </v-dialog>
    <v-dialog v-model="createfile" class="createFile" >
      <create-file
        @getOpen="OpencreateFIle"
        :nftList = "NFTlist"
      ></create-file>
    </v-dialog>
   
     <!-- 移除作品夹 -->
       <v-dialog v-model="Burning">
      <delete-folder
        :parentValue="burnInfo"  
        @getOpen="openBurn"
      ></delete-folder>
    </v-dialog>
      <v-dialog v-model="openCreateFolder" v-if="openCreateFolder">
         <createFolder  @getOpen="openCreateFolders"  :NFTInfo="FolderNFTInfo" :isCreate="creatFolder"></createFolder>
      </v-dialog>
        <v-dialog v-model="openCreateCollection" v-if="openCreateCollection">
         <createCollection  @getOpen="OpenCreateCollection" :NFTInfo="NFTInfo" :isCreate="creatCollects"></createCollection>
      </v-dialog>
  </div>
</template>

<script lang='js'>
import SortSelect from '@/components/SortSelect.vue';
import UserCard from "./UserCard.vue";
//   import Cards from "@/views/home/components/Cards.vue";
import CardList from "@/components/workCard/CardList.vue";
import api from "@/api"
import Loading from "@/components/loading.vue";
import CollectCard from '@/components/workCard/CollectCard.vue';
import Banprompt from "@/components/popup/banprompt.vue";
import CreateFile from "@/components/popup/createFile.vue";
import {share} from "@/utils";
import DeleteFolder from "@/components/popup/deleteFolder.vue";
import CreateFolder from "@/components/popup/createFolder";
import CreateCollection from "@/components/popup/createCollection";
import CrossChain from "./crossChain";
export default {
  name: 'Collections',
  components: { CrossChain,SortSelect, UserCard, CardList, Loading, CollectCard,share,CreateFile,Banprompt,DeleteFolder,CreateFolder,CreateCollection},
  props: {},
  data: function () {
    return {
      Burning:false,
      isShowCheckbox:'',
      totalNumber:0,
       Banprompt:false,
      collectionClick :false,
      folderClick:false,
      soldClick : false,
      ownerClick:true,
      activeTab: undefined,
    openCreateFolder:false,
        openCreateCollection:false,

      tabList: [
        { value: "undefined", label: this.$t('otherpageTabsPageAll') },
        { value: "3", label: this.$t('otherpageTabsPageArtwork') },
        { value: "4", label: this.$t('amCreateCollectibles') },
        { value: "5", label: this.$t('createCategoriesPhotography') },
        { value: "7", label: this.$t('amCreateVideo') }
      ],

      activeSort: '0',
      selectList: [
        { value: '0', label: this.$t('market_filter_all')},
        { value: '2', label: this.$t('MyCreations') },
        { value: '1', label: this.$t('otherpageTabsPageOnsale')},
        { value: '3', label: this.$t('collection_tag_notonsale') },
        { value: '4', label: this.$t('token_state_end')},
        { value: '5', label: this.$t('ByCreator') },

      ],
      list: [],
      pageNumber: 1,
      pageSize: 20,
      totalPage: false,
      users: [],
      loading: false,
      isShowLoading: false,
      empty: false,
      rotate:false,
      checkbox:false,
      isshownft:false,
      isshowcollect:false,
      creatCollects:false,
      creatFolder:false,
      showModel: "owned", // collection
      cardType:'my',
       NFTInfo:{
        collectionImgUrl:'',
        name:'',
        linkUrl:'',
        description:'',
        collectionMetadata:'',
        id:'',
        category:'',
        nftAddress:'',
        contractCollectionId:''
      },
      FolderNFTInfo:{
        collectionImgUrl:'',
        name:'',
        linkUrl:'',
        description:'',
        collectionMetadata:'',
        id:'',
        category:'',
        nftAddress:'',
        contractCollectionId:''
      },
      isshow:false,
      isshowBurn:false,
      share_id: 0,
      shareBurn_id:0,
      createType:'',
      NFTlist:[],
       createfile:false,
       burnInfo:{},
       checkAllCard:false,
       crossChainClick:false
    };
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
    handler: function () {
      return {
        activeTab: this.activeTab,
        activeSort: this.activeSort,
      }
    }
  },
  watch: {
    handler: {
      handler: function () {
        this.pageNumber = 1;
        this.list = [];
        this.users = [];

      
        if (this.showModel == "owned" || this.showModel == "sold") {
         if(this.isShowCheckbox =='isCreateCheck'){
             this.getNftList('noFolder');
         }else{
             this.getNftList('');
         }
      } else if (this.showModel == "collection" || this.showModel == "folder" ) {
        this.getCollectionList(this.showModel);
      }

      },
      deep: true
    },
    // checkbox(ischeck){
      
    //   if(ischeck){
    //     console.log("wxl --- ",ischeck)
    //     this.cardType = "clearmode"
    //   }else{
    //     this.cardType = "my"
    //   }
    // },
    // isMobile() {
    //   if(this.$vuetify.breakpoint.mobile) {
    //     this.checkbox = true;
    //   }
    // }
  },
  mounted() {
     this.label=this.$t("Select_All")
   this.did = this.$store.state.did
    window.eventBus.$on('LangChange', this.onLangChange);
    window.eventBus.$on('collectionPay', this.collection);
    window.eventBus.$on('collectionEdit', this.collectionEdit);
     window.eventBus.$on('nftAppealed', this.nftAppealed);
      window.eventBus.$on('closeChooseBtn', this.closeBtn);
     
      this.init();
  //  if (this.showModel == "owned") {
  //    this.getNftList();
  //  } else if (this.showModel == "collection") {
  //    this.getCollectionList();
  //  }
  },
  methods: {
    nftAppealed(){
      this.init();
    },
     closeBtn(){
      console.log("ppppppppp")
      this.createfile =false
      this.isShowCheckbox = ''
    },
    init(){
        let i=localStorage.getItem("collection_card");
       if(i==1){
         this.mode_click("owned");
        
       }else if(i==2){
         this.mode_click("sold");
       }else if(i == 3)
       {
         this.mode_click("collection"); 
       }else if(i == 4)
       {
         this.mode_click("folder"); 
       }
       else{
          this.mode_click("owned");
       }
    },
    collection(){   
      this.list = []
      this.pageNumber = 1
      this.init();
    },
    collectionEdit(){
     
      this.list = []
      this.pageNumber = 1
      if (this.showModel == "owned" || this.showModel == "sold") {
         if(this.isShowCheckbox == 'isCreateCheck'){
             this.getNftList('noFolder');
         }else{
             this.getNftList('');
         }
       
       } else if (this.showModel == "collection" || this.showModel == "folder" ) {
         this.getCollectionList(this.showModel );
       }

    },
    createCollection(){
      this.creatCollects=true

         let json = localStorage.getItem("key_user_grade");
      let jsonObj = JSON.parse(json)

         if(jsonObj.grade == 2){
               this.Banprompt = true
               console.log("wxl ---- 2222")
            }else{
               this.openCreateCollection = true
            }
     

       
    },
      OpenCreateCollection(e){

         this.openCreateCollection = e
         

    },
     createFolder(){
       this.creatFolder = true
         let json = localStorage.getItem("key_user_grade");
      let jsonObj = JSON.parse(json)

         if(jsonObj.grade == 2){
               this.Banprompt = true
               console.log("wxl ---- 2222")
            }else{
               this.openCreateFolder = true
            }
     

    },
    clickCheck(el) {
      if (el == false) {
        console.log("33333333", el, this.showAllCheck);
        this.checkAllCard = false;
        this.$forceUpdate();
      } else {
        console.log("4444444444", el, this.showAllCheck);
        this.checkAllCard = true;
        this.$forceUpdate();
      }
    },
      checkboxChange() {
      this.checkAllCard = !this.checkAllCard;
      // this.showAllCheck = this.checkAllCard;
      console.log("111111111", this.showAllCheck);
    if( this.checkAllCard){
            this.list.forEach((ele) => {
      ele.checked = true;
  });
   this.nftList = this.list.filter(item=>item.checked)
     this.totalNumber = this.nftList.length
    }else{
     this.list.forEach((ele) => {
      ele.checked = false;
    });
    }
    
      this.$forceUpdate();
    },
   
    moused(id) {
       this.share_id != 0 ? (this.share_id!=id?this.share_id=id:this.share_id=0) : this.share_id = id;
        console.log("wxl --- 3333",id)
           this.isshow = true;
         },
         leave() {
            this.share_id = 0
           this.isshow = false;
          },
          
        mousedBurn(id) {
       this.shareBurn_id != 0 ? (this.shareBurn_id!=id?this.shareBurn_id=id:this.shareBurn_id=0) : this.shareBurn_id = id;
        console.log("wxl --- 3333",id)
           this.isshowBurn = true;
         },
         leaveBurn() {
            this.shareBurn_id = 0
           this.isshowBurn = false;
          },
          
    async getNftList(type) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      let index = 0;
      if (this.activeTab == 0) {
        index = ''
      } else if (this.activeTab == 1) {
        index = 3
      } else if (this.activeTab == 2) {
        index = 4
      } else if (this.activeTab == 3) {
        index = 5
      } else if (this.activeTab == 4) {
        index = 7
      }
      
      this.isShowLoading = true;
      //创建者筛选

      if (this.activeSort == 5) {
        let params = {
          pageSize: this.pageSize,
          pageNumber: this.pageNumber,
          owner: this.$store.state.did,
      
        };
        let response = await api.home.getOwnByCreator(params);
        // let {users, totalPage} = response.data;
        // this.totalPage = totalPage;
        let user = response.data.list;
        user.forEach((v) => {
          if (!v.userName) {
            console.log("did ---- ",v.did)
            v.userName = v.did.substr(0, 6)+"..."+v.did.substr(-6);;
          }
          
        });
        this.users = this.users.concat(user);
        if (this.pageNumber == 1 && user.length == 0) {
          this.empty = true;
        } else {
          this.empty = false;
        }

      } else {
        //pageSize=20&pageNumber=1&owner=iaa1xhhf3a80n7zamu32dyku77lactynx9sgs0vvz4&filterType=0&did=iaa1xhhf3a80n7zamu32dyku77lactynx9sgs0vvz4&authenticationAddress=iaa1xhhf3a80n7zamu32dyku77lactynx9sgs0vvz4&chainType=IRIS_IRISHUB&lang=en&mobileVersion=1.3.2
           let  params = {
          pageSize: this.pageSize,
          pageNumber: this.pageNumber,
           owner: this.$store.state.did,
          filterType: this.activeSort,
        };
        if(type == 'noFolder'){
          params.filterType = 6
          params.collectionType = 2
        }
        
        let res = await api.home.getNftOwn(params);
        
       
        let { list, totalPage } = res.data;
        this.totalPage = totalPage;
    if(this.pageNumber == 1 || this.pageNumber == 0){
      this.list = list;
      
    }else{
       this.list = this.list.concat(list);

    }
       
        if ((this.pageNumber == 1 || this.pageNumber == 0) && list.length == 0) {
          this.empty = true;
        } else {
          this.empty = false;
        }

      }
      this.loading = false;

      this.isShowLoading = false;

      this.$emit("list", this.list);

    },
    async getCollectionList(mode) {
      this.loading = true;
      this.isShowLoading = true;

      let params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        owner: this.$store.state.did,
        filterType: this.activeSort,
        collectionType:mode == 'collection' ? 1 : 2
      };
      let res = await api.home.getOwnByCollection(params);
      let { list, totalPage } = res.data;
      this.totalPage = totalPage;
    if(this.pageNumber == 1 || this.pageNumber == 0) {
      this.list = list;
    }else{
       this.list = this.list.concat(list);
    }
    if ((this.pageNumber == 1 || this.pageNumber == 0) && list.length == 0) {
        this.empty = true;
    } else {
        this.empty = false;
    }
      this.loading = false;
      this.isShowLoading = false;
    },
    mode_click(mode) {
      this.showStatus(mode)
      this.showModel = mode;
       if(this.showModel == 'owned'){
          this.$gtag.event('个人主页-owned', { 'event_category': 'Click', 'event_label': 'owned' })

      }else if(this.showModel == 'collection'){
         this.$gtag.event('个人主页-collection', { 'event_category': 'Click', 'event_label': 'collection' })
      }else if(this.showModel == 'folder'){
         this.$gtag.event('个人主页-folder', { 'event_category': 'Click', 'event_label': 'folder' })
      }else if(this.showModel == 'sold'){
         this.$gtag.event('个人主页-sold', { 'event_category': 'Click', 'event_label': 'sold' })
      }
      this.pageNumber = 1;
      this.list = [];
      let isByCreate 
      if (mode == "owned" || mode == "sold") {
        this.getNftList('');
        if(this.selectList.length == 2)
        this.selectList.push(
        { value: '1', label: this.$t('otherpageTabsPageOnsale')},
        { value: '3', label: this.$t('collection_tag_notonsale') },
        { value: '4', label: this.$t('token_state_end')},
        { value: '5', label: this.$t('ByCreator') },
          )
        
      } else if (mode == "collection" || mode == "folder") {
        this.getCollectionList(mode);
        if(this.selectList.length == 6){
          this.selectList.pop(this.selectList.length -1);
          this.selectList.pop(this.selectList.length -2);
          this.selectList.pop(this.selectList.length -3);
           this.selectList.pop(this.selectList.length -4);
        }
          this.activeSort = '0'
       
      }
    },

    showStatus(mode){
      if(mode == "owned"){
          localStorage.setItem("collection_card", 1);
        this.collectionClick = false;
        this.soldClick = false;
        this.ownerClick = true;
         this.folderClick = false;
          this.crossChainClick =false
      }else if(mode == "sold"){
          localStorage.setItem("collection_card", 2);
        this.collectionClick = false;
        this.soldClick = true;
        this.ownerClick = false;
            this.folderClick = false;
             this.crossChainClick =false
      }else if(mode == "collection"){
          localStorage.setItem("collection_card", 3);
        this.collectionClick = true;
        this.soldClick = false;
        this.ownerClick = false;
         this.folderClick = false;
          this.crossChainClick =false
      }else if(mode == "folder"){
          localStorage.setItem("collection_card", 4);
          this.folderClick = true;
        this.collectionClick = false;
        this.soldClick = false;
        this.ownerClick = false;
         this.crossChainClick =false
      }else if(mode == "crossChain"){
          localStorage.setItem("collection_card", 6);
          this.folderClick = false;
       this.collectionClick = false;
        this.soldClick = false;
        this.ownerClick = false;
            this.crossChainClick =true
      }
    },
    addToFolder(){
      this.totalNumber = 0
      this.checkAllCard = false
      this.isShowCheckbox = 'isCreateCheck'
      this.pageNumber = 1;
        this.list = [];
        this.getNftList('noFolder');


    },
     subCancel(){
      this.totalNumber=0
       this.isShowCheckbox = ''
        this.pageNumber = 1;
        this.list = [];
        this.getNftList('');
    },
    subSure(){
    if(this.totalNumber==0){
       this.$toast("error",this.$t("select_coll"));
      return
    }
      this.createfile = true
    },
     OpencreateFIle(e) {
       console.log("rrrrrrrrrrrrr",e)
      this.createfile = e;
    },
       openCreateFolders(e){
      this.openCreateFolder = e
    },
      getNftLists(data) {
      
      this.NFTlist = data
      this.totalNumber = data.length;
      console.log("wxl --- getNftLists",this.NFTlist)
     
    },
     openBanprompt(e){
         this.Banprompt = e
      },
      createNft(item) {
        let imgObj={};
        imgObj.src = item.imgUrl
        imgObj.name = item.name
        imgObj.category = item.category
        imgObj.contractAddress = item.contractAddress
        imgObj.linkUrl = item.linkUrl
          imgObj.contractId =item.contractId
        imgObj.contractCollectionId = item.contractCollectionId    


        // collection信息传到创建页面
      //    console.log("wxl --- 0000",imgObj)
      // this.src = imgObj.src
      // this.contractAddress =  imgObj.nftAddress
      // this.linkUrl = imgObj.linkUrl
      // this.NFTname = imgObj.name
      // this.category = imgObj.category

         let json = localStorage.getItem("key_user_grade");
      let jsonObj = JSON.parse(json)

         if(jsonObj.grade == 2){
               this.Banprompt = true
               console.log("wxl ---- 2222")
            }else{
               this.$router.push({ name: "Create",params:{imgObj:imgObj}});
            }
     
    },
    editCollection(item){
      this.creatCollects =false
      console.log("wxl --  item ",item)
      this.createType = item.nftType
       this.NFTInfo.collectionImgUrl = item.imgUrl
        this.NFTInfo.name = item.name
        this.NFTInfo.linkUrl = item.linkUrl?item.linkUrl:item.address
        this.NFTInfo.description = item.description
        this.NFTInfo.collectionMetadata = item.collectionMetadata
        this.NFTInfo.id = item.id
        this.NFTInfo.category = item.attribute
        this.NFTInfo.nftAddress = item.nftAddress
            this.NFTInfo.contractCollectionId = item.contractCollectionId
        this.openCreateCollection = true
    //     this.$router.push({ name: "Create",params:{
    //    NFTInfo:this.NFTInfo
    // } });
  


      //  this.$router.push({name:'Create'})
    },

     editFolder(item){
         this.creatFolder = false
       this.FolderNFTInfo.collectionImgUrl = item.imgUrl
        this.FolderNFTInfo.name = item.name
        this.FolderNFTInfo.linkUrl = item.linkUrl?item.linkUrl:item.address
        this.FolderNFTInfo.description = item.description
        this.FolderNFTInfo.collectionMetadata = item.collectionMetadata
        this.FolderNFTInfo.id = item.id
        this.FolderNFTInfo.contractCollectionId = item.contractCollectionId
          this.openCreateFolder = true
    //     this.$router.push({ name: "Create",params:{
    //    NFTInfo:this.NFTInfo
    // } });



      //  this.$router.push({name:'Create'})
    },
    burnFolder(item){
      this.Burning = true;
      this.burnInfo = item
    },
      openBurn(e) {
        this.Burning = e;
      },
    
   
    onScroll(e) {
      const { scrollTop, clientHeight, scrollHeight } =
        e.target.scrollingElement;
      if (Math.floor(scrollTop) + clientHeight >= scrollHeight && (this.totalPage > this.pageNumber)) {
         if (!this.loading) {
        this.pageNumber++;
      }
       
       if (this.showModel == "owned" || this.showModel == "sold") {
         if(this.isShowCheckbox == 'isCreateCheck'){
             this.getNftList('noFolder');
         }else{
             this.getNftList('');
         }
       
       } else if (this.showModel == "collection" || this.showModel == "folder" ) {
         this.getCollectionList(this.showModel );
       }
      }
    },

       
     refresh(){
     this.rotate=true;
     setTimeout(() => { this.rotate=false }, 2000);
     this.list = []
     this.users = []
      this.pageNumber = 1
    
        if (this.showModel == "owned" || this.showModel == "sold") {
        if(this.isShowCheckbox == 'isCreateCheck'){
             this.getNftList('noFolder');
         }else{
             this.getNftList('');
         }
      } else if (this.showModel == "collection" || this.showModel == "folder") {
        this.getCollectionList(this.showModel );
      }
    
     
    },
     mousednft() {
      console.log("33333333");
      this.isshownft = true;
    },
    leavenft() {
       this.isshownft = false;
    },
    mousedcollect(){
this.isshowcollect = true
    },
     leavecollect() {
     this.isshowcollect = false
    },
    onLangChange() {
      this.selectList=[
        { value: '0', label: this.$t('market_filter_all')},
        { value: '2', label: this.$t('MyCreations') },
        { value: '1', label: this.$t('otherpageTabsPageOnsale')},
        { value: '3', label: this.$t('collection_tag_notonsale') },
        { value: '4', label: this.$t('token_state_end')},
        { value: '5', label: this.$t('ByCreator') },

      ]
    }
  },

};

</script>

<style lang='scss' scoped>
.t {
  position: relative;

}


.switch {
  margin-top: 10px;
  height: 65px;
  position: relative;
  .image {
    display: flex;
    align-items: center;
    cursor: pointer;
    .nft {
      width: 22px;
      height: 28px;
    }
  
    .collection {
      width: 25px;
      height: 25px;
      margin-left: 30px;
    }
     .top_tab {
        .btn {
          height: 25px;
          font-family:Helvetica;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 40px;
          letter-spacing: 0px;
          color: #766983;
          cursor:pointer;
        }
        .btn2 {
     
          font-family:Helvetica;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 40px;
          letter-spacing: 0px;
         color: #7800f4;;
          border-bottom: 3px solid  #7800f4;;
          padding-bottom: 10px;
          height: 40px!important;
        }
      }
 
  }
  .select {
      ::v-deep .sort-select .rd.v-chip {
    box-shadow: none;
    border: none;
    margin-bottom: 2px;
    font-weight: bold;
  }
    display: flex;
    align-items: center;
    .clearmode {
      height: 65px;
      .content {
        display: flex;
        align-items: center;
          font-size: 14px;
          font-weight: bold;
      }
    }
    .subOnsale{
             
            width: 160px;
            height: 41px;
            background-color: #f7f7f7;
            border-radius: 20px;
            
            .titleName{
            font-family:Helvetica;
            font-size: 13px;
            font-weight: bold;
            font-stretch: normal;
            line-height: 35px;
            letter-spacing: 0px;
            color: #270645;
            margin-left: 30px;
            }
            .titleName::before{
              content: "";
              background-image: url(../../../assets/icons/multi_list.png);
              width: 20px;
              height: 20px;         
              background-size: 100%;
              display: inline-block;
              position: absolute;         
              margin-top: 6px;
              margin-left: -30px;
            
          }
          
          }
    .refresh {
      height: 18px;
      width: 18px;
      margin-top: 5px;
      margin-left: 15px;
    }

    .go {
      transform: rotate(360deg);
      transition: all 2s;
      pointer-events: none;
      user-select: none;
    }
  }
}
.explain{
  position: relative;
 
  .nftexplain{
       
      height: 31px;
      background-color: #270645;
      border-radius: 3px;
      position: absolute;
      top: -10px;
      left: 0;
      z-index:999;
    
      span {
        font-family:Helvetica;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 15px;
        letter-spacing: 0px;
        color: #ffffff;
        margin-left: 13px;
        margin-right: 13px;
         width: 115px;
      }
 
    }
       .collectexplain{
    
      height: 31px;
      background-color: #270645;
      border-radius: 3px;
      position: absolute;
      top: -10px;
      left: 70px;
      z-index:1;
      span {
        font-family:Helvetica;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 15px;
        letter-spacing: 0px;
        color: #ffffff;
        margin-left: 13px;
        margin-right: 13px;
        width: 125px;
      }

    }

  
}


.empty {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-tab {
  font-size: 18px;
  border-bottom: 1px solid #e3e3e3;
}

.user-card {
  margin-right: 23px;

  &:last-child {
    margin-right: 0;
  }
}
    .totalbox {
    // max-width: 1600px;
    height: 90px;
    background-color: #270645;
     position: fixed;
     left:0;
  bottom: 0;
  width: 100%;
  line-height: var(--footer-height);
  z-index: floor($number: 999);
  //margin-left: -108px;
    .check{
    position: absolute;;
    left: 106px;
    bottom: 35px;
    &::v-deep .v-input__control .v-input__slot .v-label {
            font-family:Helvetica;
            font-size: 13px;
            font-weight: bold;
            font-stretch: normal;
            // line-height: 54px;
            letter-spacing: 0px;
            color:#ffffff;
          }
    &::v-deep .v-input__control .v-input__slot .v-input--selection-controls__input .theme--light {
            
            color:#ffffff;
          }

  }
    p {
      margin-bottom: 0;
      margin-right: 40px;
      color: #ffffff;
    }
    .subcance3l {
      width: 75px;
      height: 36px;
      background-image: linear-gradient(#ffffff, #ffffff),
        linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-style: solid;
      border-width: 2px;
     background-color: #7800f4;
      border-image-slice: 1;
      font-family:Helvetica;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 36px;
      letter-spacing: 0px;
      color: #270645;
    }
    .btnbox {
      width: 75px;
      height: 36px;
      box-sizing: border-box;
      padding: 2px;
      background-color: #7800f4;
      border-radius: 17px;

      .subcancel {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 17px;
        background: #fff;
        font-family: "\5FAE\8F6F\96C5\9ED1";
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0;
        color: #270645;
      }
    }
    .subsure {
      width: 75px;
      height: 36px;
     background-color: #7800f4;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
      border-radius: 18px;
      font-family:Helvetica;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 36px;
      letter-spacing: 0px;
      color: #ffffff;
      margin-right: 110px;
    }
  }
.collect {
  display: flex;
  flex-wrap: wrap;
  .createCollect{
    cursor: pointer;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-right: 28px;
    width: 221px;
    height: 221px;
    background-color: #ffffff;
    border-radius: 5px;
    border: solid 2px #dedede;
  img{
    margin: 40px 80px 0;
    width: 58px;
    height: 58px;
  }
  .createTitle{

    font-family: Helvetica;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #270645;
  }
  .des{
    margin: auto;
    width: 175px;
    height: 40px;
    font-family: Helvetica;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #766983;
  }
  }
 

  .card {
    margin-top: 16px;
    margin-bottom: 16px;
    margin-right: 28px;

    &:nth-child(5n) {
      margin-right: 0;
    }
     .createnft{
        display: none;
        position: absolute;
        top:90px;
        right: 30px;
      }
     
     .edit{
          cursor: pointer;
        display: none;
        position: absolute;
        top:8px;
        right: 8px;
  
        &:hover{
           .editExplain {
             display: unset !important;
        } 
        }
      }
       .burn{
          cursor: pointer;
        display: none;
        position: absolute;
        top:8px;
        right: 40px;
  
        &:hover{
           .editBurn {
             display: unset !important;
        } 
        }
      }
       .editBurn {
      // display: none !important;
      width: 110px;
      height: 30px;
      background-color: #270645;
      border-radius: 3px;
      position: absolute;
      top: 35px;
      right: 40px;
      span {
        font-family:Helvetica;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 15px;
        letter-spacing: 0px;
        color: #ffffff;
        margin-left: 13px;
        margin-right: 13px;
      }
    }
      .editExplain {
      // display: none !important;
      width: 110px;
      height: 30px;
      background-color: #270645;
      border-radius: 3px;
      position: absolute;
      top: 35px;
      right: 0px;
      span {
        font-family:Helvetica;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 15px;
        letter-spacing: 0px;
        color: #ffffff;
        margin-left: 13px;
        margin-right: 13px;
      }
    }
       &:hover {
    .createnft {
      display: unset;
    }
    .edit{
       display: unset;
    }
    .burn{
       display: unset;
    }
   

   
  }
  }
}

.mobile {
  .switch {
    position: unset;
    height: 100px;
     flex-direction: column;
  
    .select{
      display: flex;
     

    }
  }
  .cards-container{
    margin-top: 20px;
  }

  .card {
    width: 100%;
    margin-right: 0px;
    overflow: hidden;
  }

  .user-list {
    display: flex;
    justify-content: space-between;
      padding-left: 80px !important;
  }
  .user-card {
    width: 47%;
    margin-right: 0px;

    // &:last-child {
    //   margin-right: 0;
    // }
  }
  .collect{
    .createCollect{
          width: 374px;
          margin-right: 0px!important;

    }

  }
   .totalbox{
     .subsure{
       margin-right: 10px;
     }
     p{
       margin-right: 10px;
     }
     .check{
           position: absolute;
    left: 0px;
    bottom: 33px;
     }

   }
}
</style>

